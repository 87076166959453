import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PracticeExam} from '../../model/practice-exam';
import {ExamSession} from '../../model/exam-session';
import {OfficialExam} from '../../model/official-exam';
import {UtilityService} from '../../services/utility.service';
import * as moment from "moment";
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { ExamStateService } from 'src/app/services/exam-state.service';

@Component({
  selector: 'app-official-exam',
  templateUrl: './official-exam.component.html',
  styleUrls: ['./official-exam.component.scss']
})
export class OfficialExamComponent implements OnInit, OnDestroy {
  currentDate;
  exam: OfficialExam;
  session: ExamSession;
  paramObserver;
  isLoading = false;

  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService,
    private router: Router,
    private route: ActivatedRoute,
    private examStateService: ExamStateService,
    private analyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.currentDate = moment().format('D MMM YYYY');
    // Read the route parameters. The exam is not shown until these are available.
    this.paramObserver = this.route.params.subscribe(params => {
      this.isLoading = true;

      this.apiService.getExamSession(params['sid'], false, false).subscribe(
        result => {
          this.isLoading = false;
          this.exam = result['exam'];
          this.session = result['session'];

          if (this.session.hasEnded()) {
            this.router.navigate(['/official/results/' + this.session.sid]);
          }
        },
        error => {
          this.isLoading = false;
          if (error.status == 403) {
            this.utilityService.openModal('Access Denied', 'You do not have access to this session.');
          }
          else {
            this.utilityService.openModal('Error', 'We were unable to start your session. Please try again.');
          }
        });
    });
  }

  /**
   * Triggered when an answer is submitted in the question component.
   */
  onQuestionSubmitted(data) {
    // Update the local session with the newly answered question (needed to update the question overview block)
    this.session = data;
  }

  /**
   * Triggered when a new question is shown
   */
  onQuestionShown(data) {
    const title = this.examStateService.getSelectedExam().title;
    this.analyticsService.sendCustomPageViewEvent(this.router.url, `MBE Exam Simulator | ${title} | ${data.question_id}`);
  }

  /**
   * Ends the session.
   */
  endSession() {
    this.isLoading = true;

    this.apiService.endSession(this.session.sid).subscribe(
      result => {
        this.isLoading = false;
        this.router.navigate(['/official/results/' + this.session.sid]);
      },
      error => {
        this.isLoading = false;
        this.utilityService.openModal('Error', 'We were unable to submit your results. Please try again.');
      });
  }

  ngOnDestroy() {
    this.paramObserver.unsubscribe();
  }

}
