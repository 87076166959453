import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { UpgradeLinkService } from 'src/app/services/upgrade-link.service';
import {UtilityService} from '../../services/utility.service';

@Component({
  selector: 'app-notify-banner',
  templateUrl: './notify-banner.component.html',
  styleUrls: ['./notify-banner.component.scss']
})
export class NotifyBannerComponent implements OnInit, OnChanges {
  baseUrl;
  showBanner;
  inApp;
  @Input() userInfo;
  @Input() enabledBanners;

  constructor(private utilityService: UtilityService) { }

  ngOnInit() {
    this.baseUrl = this.utilityService.getApiUrl();
    this.inApp = this.utilityService.isInApp();
    this.setBanner();
  }

  /**
   * Shows the banner based on the enabledBanners and user status.
   */
  setBanner() {
    this.showBanner = null;

    if (!this.userInfo) {
      return;
    }

    if (this.userInfo.isInactive()) {
      this.showBanner = this.userInfo.paid_student ? 'expired' : 'trial_ended';
    }
    else if (this.userInfo.student_status == 'trial') {
      this.showBanner = 'trial';
    }

    // If the list if enabled banners were provided, and the one in showBanner is not enabled, hide the banner.
    if (this.showBanner && this.enabledBanners && this.enabledBanners.indexOf(this.showBanner) === -1) {
      this.showBanner = null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userInfo) {
      this.setBanner();
    }
  }

  onUpgradeClicked() {
    UpgradeLinkService.onUpgradeLinkClicked(this.utilityService.getApiUrl(), this.userInfo);
  }

}
