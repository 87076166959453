import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api.service';
import {UtilityService} from '../../services/utility.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessagingService} from '../../services/messaging.service';
import {TourServiceService} from '../../services/tour-service.service';

@Component({
  selector: 'app-annotation-enable-switch',
  templateUrl: './annotation-enable-switch.component.html',
  styleUrls: ['./annotation-enable-switch.component.scss']
})
export class AnnotationEnableSwitchComponent implements OnInit {
  annotationsEnabled;

  constructor(
    private messagingService: MessagingService,
    private storageService: LocalStorageService,
    public tourService: TourServiceService
  ) { }

  ngOnInit() {
    this.annotationsEnabled = this.storageService.getStoredValue('annotations-enabled');
  }

  annotationsEnabledChanged(state) {
    this.storageService.setStoredValue('annotations-enabled', state);
  }

}
