import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-classroom-subject-dropdown',
  templateUrl: './classroom-subject-dropdown.component.html',
  styleUrls: ['./classroom-subject-dropdown.component.scss']
})
export class ClassroomSubjectDropdownComponent implements OnInit {

  @Input()
  subject;

  @Input()
  activeComponent = 'outlines';

  subjects;

  constructor(
      private apiService: ApiService) { }

  ngOnInit() {
    this.apiService.getSubjects().subscribe(result => {
      if (result) {
        this.subjects = result;
      }
    });
  }

}
