import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as Plyr from 'plyr';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, OnChanges {
  playerType;
  player: Plyr;
  sources: Plyr.Source[];
  firstPlay = true;
  options = {
    controls: ['play-large', 'play', 'rewind', 'fast-forward', 'progress', 'current-time', 'settings', 'fullscreen'],
    seekTime: 30,
  };
  iframeUrl;
  @Input() videoFile;
  @Input() startAt;
  @Output() videoStarted: EventEmitter<any> = new EventEmitter();

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {}

  /**
   * Creates the sources array for Plyr.
   */
  loadSources() {
    let ext = this.videoFile.split('.').pop();
    let extMap = {
      'mov': 'mp4'
    };

    this.sources = [
      {
        src: this.videoFile,
        type: 'video/' + (extMap[ext] ? extMap[ext] : ext)
      },
    ];
  }

  /**
   * Fired when the video starts playing.
   */
  played(event: Plyr.PlyrEvent) {
    if (this.firstPlay && this.startAt) {
      this.firstPlay = false;
      this.player.currentTime = parseInt(this.startAt);
      this.videoStarted.emit();
    }
  }

  /**
   * Fired when the player is initialized.
   *
   * @param player
   */
  init(player) {
    this.player = player;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['videoFile']) {
      // Use an iframe for Vimeo links. Otherwise assume url is a direct video file, and use Plyr.

      // Url may be https://vimeo.com/xxx or https://player.vimeo.com/video/xxx (from embed codes)
      let vimeoMatch = this.videoFile.match(/vimeo\.com.*\/([0-9]+)/);
      if (vimeoMatch) {
        let vimeoId = vimeoMatch[1];
        let vimeoUrl = 'https://player.vimeo.com/video/' + vimeoId;

        this.playerType = 'iframe';
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(vimeoUrl);
      }
      else {
        this.playerType = 'plyr';
        this.loadSources();
      }
    }
  }

}
