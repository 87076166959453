// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apiUrlMap: {
    'app.one-timers.com': 'https://account.one-timers.com',
    'onetimers-angular.urbaninsight.com': 'https://dev-onetimers.pantheonsite.io',
    'onetimersstudentportal.urbaninsight.com': 'https://live-onetimers.pantheonsite.io',
    'production.d1sds3tmwnvsx2.amplifyapp.com': 'https://live-onetimers.pantheonsite.io',
    'test.d1sds3tmwnvsx2.amplifyapp.com': 'https://test-onetimers.pantheonsite.io',
    'master.d1sds3tmwnvsx2.amplifyapp.com': 'https://dev-onetimers.pantheonsite.io',
    'app.onetimers.loc': 'http://onetimers.loc',
    localhost: 'http://onetimers-drupal.lndo.site:8000'
  },
  apiDefaultUrl: 'https://dev-onetimers.pantheonsite.io',
  forceHttps: false,
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
