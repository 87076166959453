import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api.service';
import {switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ExamSession} from '../../model/exam-session';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  sessions = null;
  totalPages;
  activePage = 0;
  queryObservable;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.queryObservable = this.route.queryParams.pipe(switchMap(query => {
      let page = query['page'] ? parseInt(query['page']) : 0;

      this.activePage = page;
      this.sessions = null;

      return this.apiService.getAllExamSessions(page, false);

    })).subscribe(data => {
      this.sessions = data.sessions;
      this.totalPages = parseInt(data.total_pages);
    });

  }

  /**
   * Jumps to the exam results page of a session.
   */
  goToResults(session: ExamSession) {
    if (session.hasEnded()) {
      this.router.navigate(['/' + (session.exam_type == 'O' ? 'official' : 'practice') + '/results/' + session.sid]);
    }
    else {
      this.router.navigate(['/' + (session.exam_type == 'O' ? 'official' : 'practice') + '/exam/' + session.sid]);
    }
  }

  /**
   * Jumps to a flagged question.
   *
   * @param session
   * @param question_nid
   */
  goToQuestion(session: ExamSession, question_nid) {
    if (session.hasEnded()) {
      this.router.navigate(['/' + (session.exam_type == 'O' ? 'official' : 'practice') + '/results/' + session.sid]);
    }
    else {
      let index = session.exam.findQuestionIndex(question_nid);
      if (index !== null) {
        this.router.navigate(['/' + (session.exam_type == 'O' ? 'official' : 'practice') + '/exam/' + session.sid], {queryParams: {q: index}});
      }
    }
  }

  /**
   * Jumps to the give page (page is zero based)
   */
  jumpToPage(page) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: page },
    });
  }

  ngOnDestroy() {
    this.queryObservable.unsubscribe();
  }
}
