import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-tour-step-content',
  templateUrl: './tour-step-content.component.html',
  styleUrls: ['./tour-step-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TourStepContentComponent implements OnInit {
  @Input() content;

  constructor(private sanitizer: DomSanitizer) {
    this.content = sanitizer.bypassSecurityTrustHtml('<a href="/whatever"></a>');

  }

  ngOnInit() {
  }

}
