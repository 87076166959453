import { Injectable } from "@angular/core";

@Injectable()
export class TimerStateService {
  private state: {[key: number]: number} = {} // map session id to time left on session's timer

  // set time left on timer for session
  setState(sessionId: number, timeLeft: number) {
    this.state[sessionId] = timeLeft;
  }

  // get time left on timer for session
  getState(sessionId): number {
    return this.state[sessionId];
  }
}