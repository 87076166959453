import { Injectable } from '@angular/core';
import { UserInfo } from '../model/user-info';

declare var gtag

@Injectable({
  providedIn: 'root'
})
export class UpgradeLinkService {

  static onUpgradeLinkClicked(baseUrl: string, userInfo: UserInfo) {
    const upgradeLocation = `${baseUrl}/user/${userInfo.uid}/payment-options?type=${userInfo.student_type}&upgrade=1`;
    gtag('event', 'trial_upgrade', 
    {
      value: userInfo.student_type,
      bar_exam_session: userInfo.session_name,
      event_callback: function() {
        window.location.href = upgradeLocation;
      }
    })
  }
}
