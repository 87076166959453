import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {UserInfo} from './model/user-info';
import {ApiService} from './services/api.service';
import {UtilityService} from './services/utility.service';
import {MessagingService} from './services/messaging.service';
import {AccordionPanelComponent} from 'ngx-bootstrap';
import { filter } from 'rxjs/operators';
import { GoogleAnalyticsService } from './services/google-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isCollapsed = true;
  isAudioOpen = false;
  isLoggedIn = false;
  apiError = false;
  showJumpToTop = false;
  jumpToTopPages = [
    /(official|practice)\/results/g
  ];
  scrollTimer;
  userLoader;
  baseUrl;

  @ViewChild('appWrapper', {static: false}) wrapperElement: ElementRef;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.setJumpToTopDisplay();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event?) {
    if (this.scrollTimer) {
      return;
    }

    // Delay is used to prevent too many updates.
    this.scrollTimer = setTimeout(() => {
      this.setJumpToTopDisplay();
      this.scrollTimer = null;
    }, 100);
  }

  constructor(
    private router: Router,
    private apiService: ApiService,
    private messagingService: MessagingService,
    public utilityService: UtilityService,
    private analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        // Scroll to top on each pageload.
        window.scroll(0,0);
        this.setJumpToTopDisplay();
        this.apiError = false;
      }
    });

    this.router.events
      .pipe(
        filter((e: RouterEvent): e is NavigationEnd => e instanceof NavigationEnd)
      ).subscribe(event => {
        this.analyticsService.sendAutoPageViewEvent(event.urlAfterRedirects);
      }),

    this.userLoader = this.apiService.getUserInfo();
    this.baseUrl = this.utilityService.getApiUrl();

    this.userLoader.subscribe((userInfo: UserInfo) => {
      if (userInfo.isLoggedIn()) {
        this.isLoggedIn = true;
      }
      else {
        this.utilityService.externalRedirect(this.utilityService.getApiUrl() + '/user');
      }
    });

    this.messagingService.addObserver((message) => {
      if (message.messageType == 'api-access-denied') {
        this.apiError = true;
      }

      if (message.messageType == 'api-call-finished') {
        // Api requests may have loaded content and changed the content height.
        this.setJumpToTopDisplay();
      }
    }, true);
  }

  toggleCollapseHandler($event: any) {
    this.isCollapsed = $event;
  }

  audioOpenHandler($event: any) {
    this.isAudioOpen = $event;
  }

  /**
   * Click handler for the jump to top button.
   */
  jumpToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   * Checks if the jump to top button should be visible or not.
   */
  setJumpToTopDisplay() {
    this.showJumpToTop = false;

    if (!this.wrapperElement) {
      // Content not loaded yet.
      return;
    }

    this.jumpToTopPages.forEach((regex) => {
      if (this.router.url.match(regex)) {
        this.showJumpToTop = true;
      }
    });

    if (!this.showJumpToTop) {
      // Not on a page that is supposed to show the jump to top button.
      return;
    }

    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Show if content is higher than window size, and we scrolled past the first page.
    this.showJumpToTop = window.innerHeight < this.wrapperElement.nativeElement.clientHeight && scrollTop > window.innerHeight;
  }
}




