import { Injectable } from '@angular/core';
import { OfficialExam } from '../model/official-exam';

@Injectable({
  providedIn: 'root'
})
export class ExamStateService {
  private exams: OfficialExam[];
  private selectedExam: OfficialExam;

  setExams(exams: OfficialExam[]) {
    this.exams = exams;
  }
  getExams(): OfficialExam[] {
    return this.exams;
  }

  setSelectedExam(selectedExamNid: number) {
    this.selectedExam = this.exams.find((exam) => exam.nid === selectedExamNid);
  }
  getSelectedExam(): OfficialExam {
    return this.selectedExam;
  }
}
