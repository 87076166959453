 import { Component, OnInit } from '@angular/core';
import {switchMap} from 'rxjs/operators';
import {ApiService} from '../../services/api.service';
import {UtilityService} from '../../services/utility.service';
import {ActivatedRoute, Router} from '@angular/router';
import {empty} from 'rxjs';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  results = null;
  isLoading = false;
  totalPages;
  activePage;
  keyword;
  searchField;
  queryObserver;

  constructor(
    private apiService: ApiService,
    public utilityService: UtilityService,
    private route: ActivatedRoute,
    private router: Router,
    private analytics: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.queryObserver = this.route.queryParams.pipe(switchMap(query => {
      this.results = null;
      this.totalPages = 0;
      this.activePage = query['page'] ? query['page'] : 0;
      this.keyword = query['q'] ? query['q'] : '';

      if (this.keyword) {
        this.analytics.sendSearchEvent(this.keyword);
        return this.apiService.doSearch(this.keyword, this.activePage);
      }
      else {
        // Set empty result to hide loader.
        this.results = [];
        return empty();
      }

    }))
    .subscribe(data => {
        this.totalPages = data['total_pages'];
        this.results = data['results'];
      },
      error => {
        // Set empty results to hide loader.
        this.results = [];
      });
  }

  /**
   * Keydown handler for the search textfield.
   */
  onTextFieldKeyDown(event) {
    if (event.keyCode == 13) {
      this.searchClicked();
    }
  }

  /**
   * Click handler for the Search button.
   */
  searchClicked() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { q: this.searchField },
    });
  }

  /**
   * Click handler for the results.
   */
  resultClicked(result) {
    if (result.type == 'subject_section') {
      this.router.navigate(['/subjects/subject/' + result.parent_subject + '/outlines'], {queryParams: {nid: result.nid}});
    }
    else if (result.type == 'essay_question') {
      this.router.navigate(['/subjects/subject/' + result.parent_subject + '/essays'], {queryParams: {nid: result.nid}});
    }
    else if (result.type == 'support_page') {
      let url =  this.utilityService.getApiUrl() + '/node/' + result.nid;
      this.utilityService.externalRedirect(url);
    }
    else if (result.type == 'mbe_multiple_choice_question') {
      this.startExamForQuestion(result.nid);
    }
  }

  /**
   * Starts a practice exam with the selected MBE question.
   */
  startExamForQuestion(nid) {
    var options = {
      hide: 1, // We don't want this 1-question practice to show on the results page.
      show_timer: 1,
      show_answers: 0,
      question_nids: nid
    };
    this.isLoading = true;

    // Start an exam session, the redirect to the exam taking page.
    this.apiService.startPractice(options).subscribe(
      result => {
        this.router.navigate(['/practice/exam/' + result['sid']]);
      },
      error => {
        this.isLoading = false;
        this.utilityService.openModal('Error', 'We were unable to start your practice exam. Please try again.');
      });
  }

  /**
   * Click handler for the pager.
   */
  jumpToPage(page) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: { page: page },
    });
  }

  ngOnDestroy() {
    this.queryObserver.unsubscribe();
  }

}
