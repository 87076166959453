import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../services/api.service";
import {DomSanitizer} from "@angular/platform-browser";
import {UserInfo} from '../../model/user-info';

@Component({
  selector: 'app-live-classes',
  templateUrl: './live-classes.component.html',
  styleUrls: ['./live-classes.component.scss']
})
export class LiveClassesComponent implements OnInit {
  userInfo;
  classes = null;
  selectedClass = null;
  iframe_url = null;

  constructor(private apiService: ApiService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.apiService.getLiveClasses().subscribe(result => {
      if (result) {
        this.classes = result;

        if (this.classes[0]) {
          this.selectedClass = this.classes[0];
          this.iframe_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.selectedClass.video_url);
        }
      }
    });

    this.apiService.getUserInfo().subscribe((userInfo: UserInfo) => {
      this.userInfo = userInfo;
    });
  }

  /**
   * Select a class to view.
   */
  selectClass(nid) {
    for (let item = 0; item < this.classes.length; item++) {
      if (this.classes[item].nid === nid) {
        this.selectedClass = this.classes[item];
        window.scroll(0,0);
        this.iframe_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.classes[item].video_url);
      }
    }
  }

}
