import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  private observers: Array<Subscription> = [];
  private subject = new Subject<any>();

  constructor(
    private router: Router
  ) {
    // Observes added in components may stick around after the component is
    // unloaded. Remove those on route change to prevent memory leak.
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.clearObservers();
        }
      });
  }

  /**
   * Adds an observer to receive messages.
   *
   * @param {(message: any) => void} callback
   * @param saveMessageStateAcrossRoutes
   *    If true, message/data is not cleared on route change events.
   *    Defaults to true
   */
  addObserver(callback: (message: any) => void, saveMessageStateAcrossRoutes: boolean = false) {
    let observer: Subscription = this.subject.subscribe(callback);

    if (!saveMessageStateAcrossRoutes) {
      this.observers.push(observer);
    }

    return observer;
  }

  /**
   * Clears all observers.
   */
  clearObservers() {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
    this.observers = [];
  }

  /**
   * Sends a message to all current observers.
   *
   * @param {string} messageType
   *   Specifies the message type being sent. Observers can check message.messageType
   *   and react to the message type they are interested in.
   * @param data
   *   Any data to pass along with the message. May depend on the message type.
   */
  sendMessage(messageType: string, data: any = null) {
    let message = {messageType: messageType, data: data};

    if (!environment.production) {
      console.log('Sending message', message);
    }

    this.subject.next(message);

  }

}
