export class BaseModel {

  /**
   * Copies properties from a data object to the properties of this model. Only properties that exist in the subclass calling
   * this method will be copied.
   * Note, the subclass needs to give a default value to each property, otherwise they can't be enumerated with Object.keys.
   *
   * @param data
   */
  copyData(data) {
    Object.keys(this).forEach(key => {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    });
  }
}
