import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {
  className: string;
  header;
  body;
  buttons = [];

  constructor(private modalService: BsModalService) {}

  ngOnInit() {
  }

  onClose() {
    // Trigger the button that is considered the cancel button.
    this.buttons.forEach(button => {
      if (button.cancel && button.callback) {
        button.callback();
      }
    });
  }

}
