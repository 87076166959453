import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-icon-toggle',
  templateUrl: './icon-toggle.component.html',
  styleUrls: ['./icon-toggle.component.scss']
})
export class IconToggleComponent implements OnInit {
  @Input() icon;
  @Input() state = true;
  @Input() hoverText = null;
  @Input() text = '';
  @Input() color; // See scss for availble options.
  @Input() toggleOpacity = false; // If true, the opacity is set based on the state.
  @Input() toggleStyle = true; // If true, the icon style (solid or regular) is set based on the state.
  @Output() clicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    this.clicked.emit(this.state);
  }

}
