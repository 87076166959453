import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import {MessagingService} from './messaging.service';

@Injectable()
export class LocalStorageService {
  ephemeralCache = {};
  defaultLifetime = 1200; // 20 mins

  constructor(
    private messagingService: MessagingService) {

    // Check cache for expired items every 30 secs.
    setInterval(() => {
      this.clearExpiredCache();
    }, 30000);
  }

  /**
   * Puts an item in the cache.
   *
   * @param  {String} key Cache key to use.
   * @param  {any} value Data.
   * @param  {Number} lifetime Lifetime in seconds. If false, the item never expires.
   */
  setCache(key: string, value: any, lifetime: any = null) {
    this.ephemeralCache[key] = {
      data: value
    };

    if (lifetime !== false) {
      if (!lifetime) {
        // lifetime may be undefined.
        lifetime = this.defaultLifetime;
      }

      this.ephemeralCache[key].exp = (Math.floor((new Date()).getTime() / 1000) + lifetime);
    }
  }

  /**
   * Retrieves an item from cache. Deletes the item if it's expired.
   *
   * @param  {String} key Cache key.
   * @return {any} Data.
   */
  getCache(key: string) {
    if (typeof this.ephemeralCache[key] === 'undefined') {
      return null;
    }

    let now = Math.floor((new Date()).getTime() / 1000);

    if (typeof this.ephemeralCache[key].exp !== 'undefined' && this.ephemeralCache[key].exp < now) {
      delete this.ephemeralCache[key];
      return null;
    }

    return this.ephemeralCache[key].data;
  }

  /**
   * Removes expired cache entries.
   */
  clearExpiredCache() {
    let now = Math.floor((new Date()).getTime() / 1000);

    Object.keys(this.ephemeralCache).forEach(key => {
      if (typeof this.ephemeralCache[key].exp !== 'undefined' && this.ephemeralCache[key].exp < now) {
        delete this.ephemeralCache[key];
        if (!environment.production) {
          console.log('Cleared cache item for key ' + key);
        }
      }
    });
  }

  /**
   * Clears all cache items
   */
  clearCache() {
    Object.keys(this.ephemeralCache).forEach(key => {
      delete this.ephemeralCache[key];
      if (!environment.production) {
        console.log("Cleared all cache items " + key);
      }
    });
  }

  /**
   * Stores a value in the permanent local storage.
   *
   * @param {string} key
   * @param value
   */
  setStoredValue(key: string, value: any) {
    let json = JSON.stringify(value);
    localStorage.setItem(key, json);

    this.messagingService.sendMessage('stored-value-changed', {key: key, new_value: value})
  }

  /**
   * Reads a value from the permanent local storage.
   *
   * @param {any} key
   * @param {any} defaultValue
   */
  getStoredValue(key: string, defaultValue = null): any {
    let data = localStorage.getItem(key);
    if (data === null) {
      return defaultValue;
    }

    try {
      data = JSON.parse(data);
    }
    catch(e) {
      console.log(e);
      return defaultValue;
    }
    return data;
  }

  /**
   * Deletes a value from the permanent local storage.
   *
   * @param {string} key
   */
  clearStoredValue(key: string) {
    localStorage.removeItem(key);
    this.messagingService.sendMessage('stored-value-changed', {key: key, new_value: null})
  }

}
