import {Component, OnInit, Input, SimpleChanges} from '@angular/core';
import { EVENT_NAMES, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-subject-bar-charts',
  templateUrl: './subject-bar-charts.component.html',
  styleUrls: ['./subject-bar-charts.component.scss']
})
export class SubjectBarChartsComponent implements OnInit {
  @Input() selectedSession;
  data = {};
  openSubjects = {};
  subjects;

  constructor(
    private apiService: ApiService,
    private analyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  /**
   * Loads subject stats.
   */
  loadData() {
    this.data = {};
    this.subjects = null;
    this.openSubjects = {};

    this.apiService.getSubjects(true, true, false, this.selectedSession).subscribe(result => {
      if (result) {
        this.subjects = result;

        this.subjects.forEach(subject => {
          // All subjects closed by default.
          this.openSubjects[subject.nid] = false;

          this.data[subject.nid] = [
            {
              "name": "",
              "series": [
                {
                  "name": "Correct",
                  "value": subject.user_stats.correct_answered
                },
                {
                  "name": "Incorrect",
                  "value": subject.user_stats.incorrect_answered
                },
                {
                  "name": "Unanswered",
                  "value": subject.user_stats.unanswered
                }
              ]
            }
          ];

          if (!subject.sections) {
            return;
          }

          subject.sections.forEach(section => {
            this.data[section.nid] = [
              {
                "name": "",
                "series": [
                  {
                    "name": "Correct",
                    "value": section.user_stats.correct_answered
                  },
                  {
                    "name": "Incorrect",
                    "value": section.user_stats.incorrect_answered
                  },
                  {
                    "name": "Unanswered",
                    "value": section.user_stats.unanswered
                  }
                ]
              }
            ];
          });

        });
      }
    });
  }

  toggleSubject(nid) {
    this.openSubjects[nid] = !this.openSubjects[nid];
    if (!!this.openSubjects[nid]) {
      const subjectTitle
        = this.subjects.find((subject)=> subject.nid === nid).title;
      this.analyticsService.sendEvent(
        EVENT_NAMES.DASHBOARD_SUBJECT_ACCORDION,
        subjectTitle
      )
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedSession && changes.selectedSession.currentValue) {
      this.loadData();
    }
  }

}
