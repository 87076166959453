import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api.service';
import {UserInfo} from "../../model/user-info";

@Component({
  selector: 'app-classroom',
  templateUrl: './classroom.component.html',
  styleUrls: ['./classroom.component.scss']
})
export class ClassroomComponent implements OnInit {
  subjects;
  studentType;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.apiService.getSubjects().subscribe(result => {
      if (result) {
        this.processSubjects(result);
      }
    });

    this.apiService.getUserInfo().subscribe((userInfo: UserInfo) => {
      this.studentType = userInfo.student_type;
    });
  }

  processSubjects(subjects) {
    this.subjects = {
      mbe: [],
      national: []
    };

    subjects.forEach((subject) => {
      if (subject.national) {
        this.subjects.national.push(subject);
      }
      else {
        this.subjects.mbe.push(subject);
      }
    });

  }

}
