import {Component, Input, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-file-attachments',
  templateUrl: './file-attachments.component.html',
  styleUrls: ['./file-attachments.component.scss']
})
export class FileAttachmentsComponent implements OnInit {
  @Input() files;
  @Input() urlEncoded;
  displayedAttachments;

  constructor() { }

  ngOnInit() {
    this.processInput();
  }

  /**
   * Prepares the file list for display. Determines the file name from the url.
   */
  processInput() {
    this.displayedAttachments = null;

    if (!this.files || !this.files.length) {
      return;
    }

    this.displayedAttachments = [];

    for (let i = 0; i < this.files.length; i++) {
      let file = this.files[i];
      let parts = file.split('/');
      let filename = parts.pop();
      let basePath = parts.join('/');
      let url;

      // Some apis return the url in url encoded format. If just cases urlEncoded should be true.
      // Otherwise, encode it here.
      if (this.urlEncoded) {
        filename = decodeURIComponent(filename);
        url = basePath + '/' + filename;
      }
      else {
        url = basePath + '/' + encodeURIComponent(filename);
      }

      this.displayedAttachments.push({
        name: filename,
        // Filename may have #, which needs to be encoded in the link.
        url: url
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.files || changes.urlEncoded) {
      this.processInput();
    }
  }

}
