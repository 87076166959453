import {Component, OnInit, Output, EventEmitter, HostListener} from '@angular/core';
import { NavigationEnd, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {UserInfo} from '../../model/user-info';
import {MessagingService} from "../../services/messaging.service";
import {UtilityService} from "../../services/utility.service";
import {TourServiceService} from '../../services/tour-service.service';
import { UpgradeLinkService } from 'src/app/services/upgrade-link.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  upgradeVisible = false;
  unreadMessages = 0;
  unreadAssignments = 0;
  userInfo = null;
  userUid = null;
  userName = null;
  baseUrl = null;
  isCollapsed = true;
  screenWidth: number;
  trialDays = 0;
  studentType = null;
  searchVisible = false;
  searchFieldSidebar;
  searchFieldHeader;
  activeLogo;
  bmLogoPages = [
    // Pages that force the BM logo.
    /\/practice/g,
    /\/practice\/exam\/.*/g,
    /\/practice\/results\/.*/g,
    /\/official/g,
    /\/official\/exam\/.*/g,
    /\/official\/results\/.*/g
  ];

  @Output() collapseEvent = new EventEmitter<boolean>();

  constructor(private apiService: ApiService, private messagingService: MessagingService, private utilityService: UtilityService, private router: Router, public tourService: TourServiceService) {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit() {

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        // Change logo based on current route.
        this.selectLogo();

        // Force close after navigation
        if (window.innerWidth < 992) {
          // Mobile view's nav status matches the open flag. Tablet view has it reversed.
          let open = window.innerWidth < 576;

          this.isCollapsed = open;
          this.collapseEvent.emit(open);
        }
      }
    });

    this.apiService.getUserInfo().subscribe((userInfo: UserInfo) => {
      this.tourService.userInfo = userInfo;
      let inApp = this.utilityService.isInApp();

      if (userInfo.isLoggedIn() && !userInfo.hasSubscription()) {
        this.upgradeVisible = !inApp;
        this.trialDays = userInfo.trial_days_left ? userInfo.trial_days_left : 0;
      }

      if (window.innerWidth >= 992 && !userInfo.disable_tour) {
        this.tourService.openTourModal();
      }

      this.studentType = userInfo.student_type;
      this.userInfo = userInfo;
      this.unreadMessages = userInfo.unread_messages;
      this.unreadAssignments = userInfo.unread_assignments;
      this.userUid = userInfo.uid;
      this.userName = userInfo.first_name + ' ' + userInfo.last_name;

      this.selectLogo();
    });


    // Subscribe to when the unread message/assignment count changes.
    this.messagingService.addObserver((message) => {
      if (message.messageType == 'message-count-update') {
        this.unreadMessages = message.data.number;
      }
      if (message.messageType == 'assignment-count-update') {
        this.unreadAssignments = message.data.number;
      }
    }, true);

    this.baseUrl = this.utilityService.getApiUrl();
  }

  /**
   * Select the logo to display based on current path and student type.
   */
  selectLogo() {
    let forceBm = false;
    let path = this.router.url;

    if (path) {
      this.bmLogoPages.forEach((regex) => {
        if (path.match(regex)) {
          forceBm = true;
        }
      });
    }

    if (forceBm) {
      this.activeLogo = 'best_multis';
    }
    else if (this.userInfo !== null && this.studentType == 'best_multis') {
      this.activeLogo = 'best_multis';
    }
    else if (this.userInfo !== null && this.studentType != 'best_multis') {
      this.activeLogo = 'one_timers';
    }
    // Else, no logo until the student info is loaded.
  }

  /**
   * Toggle collapse functionality for menu.
   */
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.collapseEvent.emit(this.isCollapsed);
  }

  /**
   * Clicking anywhere outside of the menu hides mobile menu.
   */
  hideMobileMenu() {
    if (this.screenWidth < 576) {
      this.isCollapsed = true;
      this.collapseEvent.emit(true);
    }
  }

  /**
   * Click handler for the sidebar search button.
   */
  searchClicked() {
    this.doSearch(this.searchFieldSidebar);
  }

  /**
   * Keydown handler for the search textfield.
   */
  onTextFieldKeyDown(event, value) {
    if (event.keyCode == 13 && value) {
      this.doSearch(value);
    }
  }

  doSearch(keyword) {
    this.router.navigate(['/search'], {
      queryParams: { q: keyword },
    });

    this.searchFieldHeader = '';
    this.searchFieldSidebar = '';
    this.searchVisible = false;
  }

  onUpgradeClicked() {
    UpgradeLinkService.onUpgradeLinkClicked(this.utilityService.getApiUrl(), this.userInfo);
  }

}
