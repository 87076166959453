import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.scss']
})
export class ChartLineComponent implements OnInit {
  @Input() data;
  @Input() gradient;
  @Input() colorSet = [
    '#3B86FF', // $primary-color
    '#FF6E6E', // $red-button
    '#F0F2F8', // $light-outline
    '#999999'
  ];
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  xAxisLabel;
  yAxisLabel;

  constructor() { }

  ngOnInit() {
    this.showXAxisLabel = !!this.xAxisLabel;
    this.showYAxisLabel = !!this.yAxisLabel;
  }

}
