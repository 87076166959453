import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Observable, ReplaySubject} from 'rxjs';
import {UtilityService} from './utility.service';
import {PracticeExam} from '../model/practice-exam';
import {ExamSession} from '../model/exam-session';
import {OfficialExam} from '../model/official-exam';
import {UserInfo} from '../model/user-info';
import {ClassroomSubject} from '../model/classroom-subject';
import {MessagingService} from './messaging.service';
import { EVENT_NAMES, GoogleAnalyticsService } from './google-analytics.service';



@Injectable({
  providedIn: 'root'
})
export class ApiService {
  csrfToken = null;
  userInfo = null;

  constructor(
    private httpClient: HttpClient,
    private messagingService: MessagingService,
    private utilityService: UtilityService,
    private analyticsService: GoogleAnalyticsService
  ) {}

  /**
   * Fetches data from an api endpoint
   *
   * @param  {String} path The path of the api endpoint.
   * @param  {String} method HTTP method.
   * @param  data Data for post requests.
   * @param  headers Headers to send.
   * @param  queryParams Url query params.
   * @param  returnFullResponse
   *   If true, the returned data will contain an object with all response data (e.g. headers).
   *   Otherwise, just the json body.
   * @param  processResult
   *   A callback function to process the results returned by the api.
   *
   * @return {Observable}
   *   An observable that will emit the response data on success.
   *   If the http request fails, the observable will be rejected, the 'error' thrown back
   *   may be the error coming from the http client, if the http request fails, or a
   *   json response from the server (this happens when the server returns json where 'status' != 'ok').
   */
  makeHttpRequest(path: string, method: string = 'get', data: any = null, headers: any = null, queryParams: any = null, returnFullResponse: boolean = false, processResult: (any)=>(any) = null): Observable<any> {
    let dataResult = new ReplaySubject(1);

    let url = this.utilityService.getApiUrl() + path + '?_format=json';
    let options: any = {withCredentials: true};
    let request;

    if (headers) {
      options.headers = new HttpHeaders(headers);
    }

    if (queryParams) {
      options.params = queryParams;
    }

    if (returnFullResponse) {
      options.observe = 'response';
    }

    if (method === 'patch') {
      request = this.httpClient.patch(url, data, options);
    }
    else if (method === 'post') {
      request = this.httpClient.post(url, data, options);
    }
    else if (method === 'delete') {
      request = this.httpClient.delete(url, options);
    }
    else {
      request = this.httpClient.get(url, options);
    }

    this.utilityService.debugLog('Initiating ' + method + ' api call to ' + url);

    // Allows components to prepare for delay (eg. put up load indicator)
    // this.messagingService.sendMessage('apiCallStarting', {url: url, cacheKey: cacheKey});

    let successCallback = result => {
      this.utilityService.debugLog('Got api response for url ' + url);
      this.utilityService.debugLog(result);

      // The api may return a .status attribute, which should be 'ok' if the request has no error. Update requests may return null.
      // If it is anything else, consider it a failure.
      if ((method != 'get' && result === null) || (result && !result['status']) || (result && result['status'] == 'ok')) {
        if (processResult) {
          result = processResult(result);
        }
        dataResult.next(result);
      }
      else {
        this.utilityService.debugLog('Api call returned status error: ' + (result ? result['status'] : 'null'));
        dataResult.error(result ? result : {});
      }

      // Send notification with a delay so that the observers can handle the response and updated content first.
      setTimeout(() => {
        this.messagingService.sendMessage('api-call-finished', {url: url});
      }, 100);

      dataResult.complete();
    };

    let errorCallback = error => {
      this.utilityService.debugLog('Api call failed', true);
      this.utilityService.debugLog(error, true);

      if (error.status == 403) {
        this.messagingService.sendMessage('api-access-denied', {url: url, error: error});
      }

      dataResult.error(error);
      dataResult.complete();
    };

    request.subscribe(successCallback, errorCallback);

    return dataResult;
  }

  /**
   * Makes update related request that need a csrf token. Requests a token,
   * if needed, then passes the request to makeHttpRequest.
   *
   * @param {string} url
   * @param data
   * @param {string} method
   * @param {boolean} returnFullResponse
   * @param processResult
   *
   * @return {Observable<any>}
   *  See makeHttpRequest
   */
  makeHttpUpdateRequest(url: string, data: any, method: string = 'post', returnFullResponse: boolean = false, processResult: (any)=>(any) = null) {
    let dataResult = new ReplaySubject(1);
    if (!data) {
      data = {};
    }

    // Makes the original request and passed results to dataResult.
    let makeRequest = (url, data) => {
      let headers: any = {};

      if (this.csrfToken) {
        data.csrf_token = this.csrfToken;
        headers['X-CSRF-Token'] = this.csrfToken;
      }

      this.makeHttpRequest(url, method, data, headers, null, returnFullResponse, processResult).subscribe(
        result => {
          dataResult.next(result);
          dataResult.complete();
        }, error => {
          dataResult.error(error);
          dataResult.complete();
        }
      );
    };

    // Only request token if userInfo is set. Otherwise assume user is not logged in. (@TODO)
    if (!this.csrfToken && this.userInfo) {
      this.utilityService.debugLog('Getting token for patch request');

      // Need to get token before firing the original request.
      this.getCsrfToken().subscribe(token => {
        if (!token) {
          this.utilityService.debugLog('Unable to get token');

          dataResult.error(null);
          dataResult.complete();
          return;
        }

        this.utilityService.debugLog('Got token ' + token);

        this.csrfToken = token;
        makeRequest(url, data);
      });
    }
    else {
      makeRequest(url, data);
    }

    return dataResult;
  }

  /**
   * Returns info about the currently logged-in user. Return null, if the
   * user is not logged in, or the api call fails.
   *
   * @param refresh
   *   If true, and the user info is already cached, the cached data is
   *   returned, but the api call is also made, and the a second set of data
   *   returned when the api finishes.
   *   Note: if true, the caller needs to be prepared for two resolutions
   *   on the returned promise.
   *
   * @return UserInfo
   *   Returns a UserInfo object, where isLoggedIn() is false if the authentication fails.
   *
   */
  getUserInfo(refresh: boolean = false): Observable<UserInfo> {
    let dataResult = new ReplaySubject<UserInfo>(2);

    if (this.userInfo !== null) {
      if (!refresh) {
        this.utilityService.debugLog('User info already saved, returning cached data');

        dataResult.next(this.userInfo);
        dataResult.complete();
        return dataResult;
      }
      else {
        this.utilityService.debugLog('User info already saved, returning, but refreshing data');
        dataResult.next(this.userInfo);
      }
    }

    this.makeHttpRequest('/api/student').subscribe(result => {
        if (result) {
          this.userInfo = new UserInfo(result);
        }
        else {
          // Store the fact that auth has failed by creating an anonymous user object.
          this.userInfo = new UserInfo({});
        }

        dataResult.next(this.userInfo);
        dataResult.complete();
      },
      error => {
        // The endpoint returns 403 if the user is not logged in.
        this.userInfo = new UserInfo({});

        dataResult.next(this.userInfo);
        dataResult.complete();
      });

    return dataResult;
  }

  /**
   * Generates a csrf token. Returns observable that yields the token, or false
   * on error.
   *
   * @return {Observable<any>}
   */
  getCsrfToken(): Observable<any> {
    let dataResult = new ReplaySubject(1);

    this.makeHttpRequest('/api/token').subscribe(
      result => {
        dataResult.next(result.csrf_token ? result.csrf_token : false);
        dataResult.complete();
      },
      error => {
        dataResult.next(false);
        dataResult.complete();
      });

    return dataResult;
  }

  /**
   * Returns a list of pending notifications for the current user.
   *
   * @param stats
   *   Include stats for each subject/section
   * @param national
   *   If true, only national stats are returned.
   * @param essays
   *   If true, essay data are included.
   * @param session_nid
   *   Session to filter stats for.
   */
  getSubjects(stats = false, national = false, essays = false, session_nid = null): Observable<any> {
    let query = {};
    if (stats) {
      query['stats'] = 1;
    }
    if (national) {
      query['national'] = 1;
    }
    if (essays) {
      query['essays'] = 1;
    }
    if (session_nid) {
      query['session_nid'] = session_nid;
    }
    return this.makeHttpRequest('/api/subjects', 'get', null, null, query);
  }

  /**
   * Returns all classroom data by subject.
   * By default, it does not include outline section and essay content (only partially) - for the purpose of enabling tracking of
   * individual page views.
   *
   * @param nid
   *   Subject nid.
   * @param component
   * @param component_nid
   *   If specified, only the given component node data is loaded (e.g. one outline page or one essay page).
   *
   */
  getFullSubject(nid, component = null, component_nid = null): Observable<ClassroomSubject> {
    let processResult;
    let query = {};

    if (component) {
      query['component'] = component;
      query['component_nid'] = component_nid;
    }
    else {
      // If not loading a subcomponent, convert the returned object to ClassroomSubject.
      processResult = (result) => {
        return new ClassroomSubject(result);
      };
    }

    return this.makeHttpRequest('/api/classroom/' + nid, 'get', null, null , query , false, processResult);
  }

  /**
   * Returns a list of live classes for the user.
   */
  getLiveClasses(): Observable<any> {
    return this.makeHttpRequest('/api/live-class');
  }

  /**
   * Returns a list of resources for the user.
   */
  getResources(): Observable<any> {
    return this.makeHttpRequest('/api/resources');
  }

  /**
   * Returns a list of assignments for the user.
   */
  getAssignments(): Observable<any> {
    return this.makeHttpRequest('/api/assignment');
  }

  /**
   * Returns a list of assignment answers submitted by the user.
   */
  getAssignmentAnswers(): Observable<any> {
    return this.makeHttpRequest('/api/assignment-answer');
  }

  /**
   * Sends assignment answer.
   *
   * @param title
   *   Title of the assignment answer.
   * @param assignment_id
   *   The node id of the related assignment.
   * @param file
   *   binary version of file.
   * @param file_type
   *   extension name of file.
   */
  submitAssignmentAnswer(title, assignment_id, file, file_type) {
    let data = {
      action: 'save_assignment',
      title: title,
      assignment_id: assignment_id,
      file: file,
      file_type: file_type
    };
    return this.makeHttpUpdateRequest('/api/assignment-answer', data, 'post');
  }

  /**
   * Resets the current user's exam data.
   */
  resetExamData() {
    let data = {};
    data['action'] = 'reset_exam_data';
    return this.makeHttpUpdateRequest('/api/exam', data, 'post');
  }

  /**
   * Sends that the student has clicked to download the assignment answer grade.
   *
   * @param answer_id
   *   The node id of the answer.
   * @param viewed
   *   Whether or not the grade was viewed.
   */
  gradeDownloaded(answer_id, viewed) {
    let data = {
      action: 'grade_downloaded',
      answer_id: answer_id,
      viewed: true,
    };
    return this.makeHttpUpdateRequest('/api/assignment-answer', data, 'post');
  }

  /**
   * Returns all student classroom data.
   */
  getClassroomStudentData(): Observable<any> {
    return this.makeHttpRequest('/api/classroom-student', 'get');
  }

  /**
   * Marks a section or essay as complete.
   *
   * @param nid
   *   The node id of the section or essay.
   * @param complete
   *   1 or 0 depending on if the section is complete.
   */
  markAsComplete(nid, complete = 1) {
    let data = {
      action: 'mark_as_complete',
      nid: nid,
      complete: complete
    };
    return this.makeHttpUpdateRequest('/api/classroom-student', data, 'post');
  }

  /**
   * Marks a section or essay as complete.
   *
   * @param nid
   *   The node id of the section or essay.
   * @param value
   *   new value of the note to save.
   */
  patchNote(nid, value) {
    let data = {
      action: 'patch_note',
      nid: nid,
      note: value
    };
    return this.makeHttpUpdateRequest('/api/classroom-student', data, 'post');
  }

  /**
   * Returns a list of messages for the student's session.
   */
  getMessages(page): Observable<any> {
    let query = {};
    if (page) {
      query['page'] = page;
    }
    return this.makeHttpRequest('/api/daily-message', 'get', null, null, query);
  }

  /**
   * Marks messages as read or unread.
   *
   * @param params
   *   array of messages to mark {message_nid: nid, message_read: 1}
   */
  markMessagesAsRead(params) {
    let data = {
      params: params,
    };
    return this.makeHttpUpdateRequest('/api/daily-message', data, 'post');
  }

  /**
   * Starts a practice exam, creates the exam with random questions and starts the session..
   *
   * @param data
   *   See ExamResource::startPractice().
   *
   * @return {ReplaySubject<any>}
   */
  startPractice(data: {}) {
    data['action'] = 'start_practice';
    return this.makeHttpUpdateRequest('/api/exam', data, 'post');
  }

  /**
   * Starts an official exam session.
   *
   * @param nid
   *   The node if of the official exam.
   *
   * @return {ReplaySubject<any>}
   */
  startOfficial(nid) {
    let data = {
      action: 'start_official',
      nid: nid
    };
    return this.makeHttpUpdateRequest('/api/exam', data, 'post');
  }

  /**
   * Retrieves an exam object, or a list o exams.
   *
   * @param type
   *   'practice' or 'official'.
   * @param id
   *   Exam id (practice table row id, or official exam node id)
   *   If omitted, a list of exams are fetched.
   */
  getExam(type, id = null) {
    let path = '/api/exam/' + type;
    if (id) {
      path = path + '/' + id;
    }

    let processResult = (result) => {
      if (id) {
        return type == 'official' ? new OfficialExam(result.exam) : new PracticeExam(result.exam);
      }
      else {
        // Requesting multiple exams.
        let exams = [];

        result.exams.forEach(item => {
          exams.push(type == 'official' ? new OfficialExam(item) : new PracticeExam(item),);
        });

        return exams;
      }
    };

    return this.makeHttpRequest(path, 'get', null, null, null, false, processResult);
  }

  /**
   * Retrieves performance stats.
   *
   * @param start_time
   * @param end_time
   *   Timestamps.
   * @param subject
   *   Subject nid.
   * @param subject
   *   Bar session nid, to filter the stats for.
   * @param session_nid
   *   Session to filter for.
   */
  getExamStats(start_time = null, end_time = null, subject = null, session_nid = null) {
    let params = {};
    params['start_time'] = start_time ? start_time : this.utilityService.now() - 24 * 3600;
    params['end_time'] = end_time ? end_time: this.utilityService.now();
    params['subject'] = subject;
    if (session_nid) {
      params['session_nid'] = session_nid;
    }

    return this.makeHttpRequest('/api/exam/stats', 'get', null, null, params);
  }

  /**
   * Retreives all the user's sessions.
   *
   * @param graded
   *   If true, the session data will include which answers are correct and which are not.
   * @param completed
   *   Only completed sessions are included.
   * @param page
   *   Page number to return (zero based).
   * @param maxItems
   *   Maximum number of items to return
   */
  getAllExamSessions(page, completed = false, maxItems = 10, graded = true) {
    // When loading all sessions, the response contains:
    // 'total_items': number of sessions
    // 'total_pages': number of pages
    // 'sessions': an array of sessions.
    let processResult = (result) => {
      if (result['sessions']) {
        result['sessions'] = result['sessions'].map(data => new ExamSession(data));
      }
      return result;
    };

    let query = {};
    query['page'] = page;
    query['max_items'] = maxItems;

    if (graded) {
      query['graded'] = 1;
    }

    if (completed) {
      query['completed'] = 1;
    }

    return this.makeHttpRequest('/api/exam/session', 'get', null, null, query, false, processResult);
  }

  /**
   * Retrieves an exam session, which includes the session (which include submitted answers), and the exam data.
   *
   * @param sid
   *   Session id.
   * @param graded
   *   If true, the session data will include which answers are correct and which are not.
   * @param load_content
   *   If false, question content will be partially loaded. Used to be able to track question views.
   */
  getExamSession(sid, graded = false, load_content = true) {
    // When loading one specific session, the response contains the related exam.
    let processResult = (result) => {
      return {
        exam: new PracticeExam(result.exam),
        session: new ExamSession(result.session)
      };
    };

    let query = {};
    if (graded) {
      query['graded'] = 1;
    }
    if (load_content) {
      query['load_content'] = 1;
    }

    return this.makeHttpRequest('/api/exam/session/' + sid, 'get', null, null, query, false, processResult);
  }

  /**
   * Retrieves an individual exam question.
   * Needed because the exam object by default does not include full question content (for tracking purposes)
   *
   * @param sid
   *   Session id.
   * @param question_nid
   *   Question nid.
   */
  getExamQuestion(sid, question_nid) {
    let query = {
      question_nid: question_nid
    };

    return this.makeHttpRequest('/api/exam/session/' + sid, 'get', null, null, query);
  }

  /**
   * Submits an answer to the api.
   *
   * @param session_id
   *   The exam taking session id.
   * @param question_nid
   * @param answer
   */
  submitAnswer(session_id, question_nid, answer, time) {
    let data = {
      action: 'submit_answer',
      sid: session_id,
      question_nid: question_nid,
      answer: answer,
      time: time
    };

    let processResult = (result) => {
      return {
        session: new ExamSession(result.session)
      };
    };

    return this.makeHttpUpdateRequest('/api/exam', data, 'post', false, processResult);
  }

  /**
   * Flags a question in a session.
   *
   * @param session_id
   *   The exam taking session id.
   * @param question_nid
   */
  flagQuestion(session_id, question_nid, state) {
    let data = {
      action: 'flag_question',
      sid: session_id,
      state: state,
      question_nid: question_nid,
    };

    if (!!state) {
      this.analyticsService.sendEvent(
        EVENT_NAMES.MBE_QUESTION_FLAGGED,
        question_nid
      )
    }

    return this.makeHttpUpdateRequest('/api/exam', data, 'post');
  }

  /**
   * Ends an exam session..
   *
   * @param session_id
   *   The exam taking session id.
   */
  endSession(session_id) {
    let data = {
      action: 'end_session',
      sid: session_id,
    };

    return this.makeHttpUpdateRequest('/api/exam', data, 'post');
  }

  /**
   * Saves a user setting name/value pair.
   *
   * @param name
   * @param value
   */
  setStudentValue(name, value) {
    let data = {};
    data[name] = value;
    return this.makeHttpUpdateRequest('/api/student', data, 'post');
  }

  /**
   *
   *
   * @param sid
   *   Session id.
   * @param question_nid
   *   Question nid.
   */
  doSearch(keyword, page = 0) {
    let query = {
      q: keyword
    };

    if (page) {
      query['page'] = page;
    }

    return this.makeHttpRequest('/api/search', 'get', null, null, query);
  }
}
