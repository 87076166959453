import { Injectable } from '@angular/core';

declare var gtag

export const EVENT_NAMES = {
  DASHBOARD_DATE_RANGE_SUBJECT_FILTER: "Dashboard Date Range Subject Filter",
  DASHBOARD_DATE_RANGE_TIME_PERIOD_UNTIS: "Dashboard Date Range Time Period Units",
  DASHBOARD_DATE_RANGE_CUSTOM_DATE_FRAME: "Dashboard Date Range Custom Date Frame",
  DASHBOARD_DATE_RANGE_TOGGLE_COMPARATIVE_CHARTS: "Dashboard Date Range Toggle Comparative Charts",
  DASHBOARD_SUBJECT_ACCORDION: "Dashboard Subject Accordion Opened",
  MBE_QUIZ_MAKER_QUESTION_COUNT: "MBE Quiz Maker Question Count",
  MBE_QUIZ_MAKER_PREFERENCES: "MBE Quiz Maker Preferences",
  MBE_QUESTION_FLAGGED: "MBE Question Flagged",
  QUIZ_TIMER_PAUSED: "Quiz Timer Paused",
  TEXT_HIGHLIGHTED: "Text Highlighted",
  TAKE_NOTES_DRAWER_OPENED: "Take Notes Drawer Opened",
  MEDIA_PLAYED: "Media Played"
};

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  sendEvent(eventName: string, value?: string) {
    const data = {
      value: null,
    }

    if (value) {
      data.value = value;
    }

    gtag('event', eventName, data);
  }

  sendAutoPageViewEvent(url: string) {
    // ignore routes that have custom page_view event(s) set up
    const strippedUrl = url.split('?')[0]
    if (
      strippedUrl === 'home'
      || strippedUrl.includes('subjects/subject')
      || strippedUrl === 'practice'
      || strippedUrl.includes('practice/exam/')
      || strippedUrl.includes('practice/results/')
      || strippedUrl === 'official'
      || strippedUrl.includes('official/exam/')
    ) {
      return;
    }
    
    gtag('event', 'page_view', {
      page_path: url
    });
  }

  sendCustomPageViewEvent(url: string, title: string) {
    gtag('set', 'page_path', url);
    gtag('set', 'page_title', title);
    gtag('event', 'page_view');
  }

  sendSearchEvent(keyword: string) {
    gtag('event', 'search', {
      search_term: keyword
    });
  }
}
