import {Injectable} from '@angular/core';
import {
  Router, CanActivate, ActivatedRoute,
  ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';
import {ReplaySubject, Subject} from "rxjs";
import {environment} from '../../environments/environment';
import {ApiService} from './api.service';
import {UtilityService} from './utility.service';
import {UserInfo} from '../model/user-info';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    public apiService: ApiService,
    public utilityService: UtilityService,
    public router: Router
  ) {}

  /**
   * Called before a route is activated. If it returns false, the access is denied.
   * User is redirected to /
   *
   * @return {ReplaySubject<boolean>}
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (environment.forceHttps && (location.protocol !== 'https:')) {
      location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
      return false;
    }

    let dataResult = new ReplaySubject<boolean>(1);
    this.apiService.getUserInfo().subscribe((userInfo: UserInfo) => {

      if (!userInfo.canAccessPage(state.url)) {
        this.router.navigate(['/']);
        dataResult.next(false);
      }
      else {
        dataResult.next(true);
      }

      dataResult.complete();
    });

    return dataResult;
  }
}
