import {Component, OnDestroy, OnInit, ViewChildren, ElementRef, QueryList} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {UtilityService} from "../../services/utility.service";
import {MessagingService} from "../../services/messaging.service";
import {UserInfo} from "../../model/user-info";
import * as moment from 'moment';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss']
})
export class AssignmentsComponent implements OnInit {
  userInfo;
  assignments;
  assignmentAnswers;
  studentStatus = null;
  futureSessionStart = null;

  fileUploadInProgress;
  @ViewChildren('uploads') uploads: QueryList<any>;

  constructor(private apiService: ApiService, private utilityService: UtilityService, private messagingService: MessagingService) { }

  ngOnInit() {
    // Mark all assignments and grades as seen.
    this.apiService.setStudentValue('assignments_viewed', 1).subscribe(() => {
      // Reset unread badge.
      this.messagingService.sendMessage('assignment-count-update', {number: 0});
    });

    this.apiService.getAssignments().subscribe(result => {
      if (result) {
        this.assignments = result;

        this.refreshAnswerData();
      }
    });

    this.apiService.getUserInfo(true).subscribe((userInfo: UserInfo) => {
      this.userInfo = userInfo;
      this.studentStatus = userInfo.student_status;

      // If the subscribe session has a future date, prepare that for display.
      if (this.userInfo.in_assigned_session && this.userInfo.subscribed_session_start_date) {
        var start = moment(this.userInfo.subscribed_session_start_date);
        if (start.isAfter(moment())) {
          this.futureSessionStart = start.format('MMMM D, YYYY');
        }
      }
    });
  }

  getAssignmentAnswer(nid) {
    return this.assignmentAnswers.find(function(answer) {
      return answer.assignment_id === nid;
    });
  }

  /**
   * Called when the 'upload file' link is clicked.
   */
  uploadFileClicked(nid) {
    // Trigger the click event on the file input to show the upload form.
    this.uploads.forEach(upload => {
        if (upload.nativeElement.id === 'uploader-'+nid) {
          upload.nativeElement.click();
        }
      }
    );
  }

  /**
   * Called when a file is selected in the popup of the upload input element.
   */
  fileSelected($event, nid) {
    for (let item = 0; item < this.assignments.length; item++) {
      if (this.assignments[item].nid === nid) {
        this.assignments[item].selected_file = $event.target.files[0];
      }
    }
  }

  /**
   * Called the the 'complete' button is clicked.
   */
  completeClicked(assignment) {
    if (this.fileUploadInProgress) {
      return;
    }

    if (!assignment.selected_file) {
      this.utilityService.openModal('Error', 'Please select a file to upload.');
      return;
    }

    // Read the file, the make the api call.
    let reader = new FileReader();
    reader.onload = (e: any) => {
      this.fileUploadInProgress = true;
      // e.target.result will have the binary file.
      let base64Data = btoa(e.target.result);

      this.apiService.submitAssignmentAnswer(assignment.title, assignment.nid, base64Data, assignment.selected_file.name.split('.').pop()).subscribe(
        result => {
          // Hide complete button.
          assignment.selected_file = null;

          this.fileUploadInProgress = false;
          this.refreshAnswerData();
        },
        response => {
          this.fileUploadInProgress = false;

          let message;
          if (response.error.error) {
            message = response.error.error;
          }
          else {
            message = 'Your file cannot be uploaded.';
          }

          this.utilityService.openModal('Error', message);
        });
    };

    // Read file.
    reader.readAsBinaryString(assignment.selected_file);
  }

  /**
   * Record when a student has viewed the grade of an answer.
   */
  gradeViewed(nid) {
    this.apiService.gradeDownloaded(nid, true).subscribe(result => {
      if (result) {
        this.refreshAnswerData();
      }
    });
  }

  /**
   * Refresh student data
   */
  refreshAnswerData() {
    this.apiService.getAssignmentAnswers().subscribe(result => {
      if (result) {
        this.assignmentAnswers = result;

        for (let item = 0; item < this.assignments.length; item++) {
          let file = this.assignments[item].attachment ? this.assignments[item].attachment : '';
          this.assignments[item].attachment_filename = file.split('/').pop();
          this.assignments[item].answer = this.getAssignmentAnswer(this.assignments[item].nid.toString());
        }
      }
    });
  }

}
