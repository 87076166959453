import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {UtilityService} from '../../services/utility.service';
import * as moment from "moment";
import {UserInfo} from '../../model/user-info';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-official',
  templateUrl: './official.component.html',
  styleUrls: ['./official.component.scss']
})
export class OfficialComponent implements OnInit {
  currentDate;
  isLoading = false;
  selectedExam;
  userInfo;

  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService,
    private router: Router,
    private analyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.currentDate = moment().format('D MMM YYYY');

    this.apiService.getUserInfo().subscribe((userInfo: UserInfo) => {
      this.userInfo = userInfo;
    });

    this.analyticsService.sendCustomPageViewEvent(this.router.url, 'MBE Exam Simulator | Select Exam');
  }

  /**
   * Called when the start button is clicked within the exam selector.
   *
   * @param nid
   *  Selected exam node id.
   */
  examSelected(nid) {
    this.selectedExam = nid;
    this.analyticsService.sendCustomPageViewEvent(this.router.url, 'MBE Exam Simulator | Instructions');
  }

  /**
   * Called when the start button on the instruction page is clicked.
   */
  startExamClicked() {
    this.isLoading = true;

    // Start an exam session, the redirect to the exam taking page.
    this.apiService.startOfficial(this.selectedExam).subscribe(
      result => {
        this.selectedExam = null;
        this.isLoading = false;
        this.router.navigate(['/official/exam/' + result['sid']]);
      },
      error => {
        this.selectedExam = null;
        this.isLoading = false;
        this.utilityService.openModal('Error', 'We were unable to start your session. Please try again.');
      });
  }

}
