import {Component, Inject, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {UtilityService} from "../../services/utility.service";
import * as moment from "moment";
import {UserInfo} from "../../model/user-info";
import {ClassroomSubject} from '../../model/classroom-subject';
import {switchMap, tap} from 'rxjs/operators';
import {empty, merge, Observable} from 'rxjs';
import {MessagingService} from '../../services/messaging.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-classroom-subject',
  templateUrl: './classroom-subject.component.html',
  styleUrls: ['./classroom-subject.component.scss']
})
export class ClassroomSubjectComponent implements OnInit, OnDestroy {
  subjectNid;
  subject: ClassroomSubject;
  flattenedSubject;
  loadObserver;

  activeComponent; // 'essays' or 'outlines'
  activeItem; // Currently active essay or section.
  activeStudentData;

  studentData;
  currentDate;
  studentType;

  constructor(
      private apiService: ApiService,
      private router: Router,
      private route: ActivatedRoute,
      private messagingService: MessagingService,
      private utilityService: UtilityService,
      private analyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.currentDate = moment().format('D MMM YYYY');

    this.apiService.getClassroomStudentData().subscribe(result => {
      if (result) {
        this.studentData = result;
      }
    });

    this.apiService.getUserInfo().subscribe((userInfo: UserInfo) => {
      this.studentType = userInfo.student_type;
    });

    let paramObservable = this.route.params.pipe(switchMap(params => {
      this.activeComponent = params['activeComponent'];

      if (this.subjectNid === params['nid']) {
        // Changing between activeComponent, skip reloading the section.
        return empty();
      }
      this.subjectNid = params['nid'];

      // Clear data to show loader.
      this.subject = null;
      this.activeItem = null;
      this.activeStudentData = null;

      return this.apiService.getFullSubject(this.subjectNid);
    }),
    switchMap(result => {
      this.subject = result;

      this.flattenedSubject = this.makeSubjectFlat(this.subject.sections);

      if (this.route.snapshot.queryParams['nid']) {
        // If there is a query param on the initial page load, we missed that
        // because the data wasn't loaded when queryObserver triggered.
        // Navigate to the section in the query.
        return this.navigateToNid(this.route.snapshot.queryParams['nid']);
      }

      return empty();
    }));

    let queryObservable = this.route.queryParams.pipe(switchMap(query => {
      if (!this.subject) {
        // Data is not loaded yet.
        return empty();
      }

      return this.navigateToNid(query['nid']);
    }));

    // paramObservable and queryObservable emit when data is loaded by navigateToNid. That result is processed here.
    this.loadObserver = merge(paramObservable, queryObservable).subscribe(data => {
      // Update the data both in this.subject and activeItem.
      let updatedData = this.subject.mergeData(data);

      if (this.activeItem.nid == updatedData.nid) {
        this.activeItem = updatedData;
      }
    });
  }

  ngOnDestroy() {
    this.loadObserver.unsubscribe();
  }

  /**
   * Flattens subject/section hierarchy.
   */
  makeSubjectFlat(array) {
    var i;
    var result = [];
    for (i = 0; i < array.length; i++) {
      result.push(array[i]);
      if (Array.isArray(array[i].sections)) {
        result = result.concat(this.makeSubjectFlat(array[i].sections));
      }
    }
    return result;
  }

  /**
   * Click handler for the prev/next buttons and section outline's links.
   */
  queryNavClicked(nid) {
    // Triggers this.navigateToSection().
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {nid: nid}
    });
  }

  /**
   * Refresh student data
   */
  refreshStudentData(nid) {
    this.apiService.getClassroomStudentData().subscribe(dataResult => {
      this.studentData = dataResult;
      this.activeStudentData = this.activeItem ? this.getStudentData(this.activeItem.nid)[0] : false;
    });
  }

  /**
   * Find student data for a specific section or essay
   */
  getStudentData(nid){
    return this.studentData.filter(function(obj){
      if (obj.nid===nid){
        return obj;
      }
    });
  }

  /**
   * Click handler for essay or section navigation checkbox
   */
  studentDataChanged(params) {
    this.apiService.markAsComplete(params.nid, params.complete).subscribe(result => {
      if (result) {
        this.refreshStudentData(params.nid);
      }
    });
  }

  /**
   * Handler for when a section id in the query parameter changes.
   * Should be called during the observable chain of query updates. Returns an observable that emits the loaded section, if it needs
   * to be loaded.
   **/
  navigateToNid(nid) {
    if (this.activeComponent == 'essays') {
      this.activeItem = this.subject.getEssay(nid);
    }
    else {
      this.activeItem = this.subject.getSubject(nid);
    }

    this.activeStudentData = this.activeItem ? this.getStudentData(this.activeItem.nid)[0] : null;

    if (nid) {
      this.analyticsService.sendCustomPageViewEvent(this.router.url, `${this.subject.title} | ${this.activeItem.title}`);
    }

    if (this.activeItem && this.activeItem.content_loaded === false) {
      return this.apiService.getFullSubject(this.subjectNid, this.activeComponent, this.activeItem.nid)
        .pipe(
          tap(e => {
            this.scrollToContent();
          })
        );
    }
    else {
      this.scrollToContent();
      return empty();
    }
  }

  /**
   * Scrolls to the window top, or the component if scrollto=[contentid] is in the url.
   * Note, this should run after the content has loaded, because the child component hides
   * the video element until it is loaded.
   */
  scrollToContent() {
    if (this.route.snapshot.queryParams['scrollto']) {
      // setTimeout is needed to let the variable change event propagate.
      // If there is a scrollto parameter, let the child components know to show the appropriate content.
      setTimeout(() => {
        this.messagingService.sendMessage('scroll-to-content', this.route.snapshot.queryParams['scrollto']);
      }, 100);
    }
    else {
      window.scroll(0, 0);
    }
  }

}
