import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ExamSession} from '../../model/exam-session';
import {Subscription, timer} from 'rxjs';
import {ExamBase} from '../../model/exam-base';
import {UtilityService} from '../../services/utility.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {MessagingService} from '../../services/messaging.service';
import { TimerStateService } from 'src/app/services/timer-state.service';
import { EVENT_NAMES, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-exam-timer',
  templateUrl: './exam-timer.component.html',
  styleUrls: ['./exam-timer.component.scss']
})
export class ExamTimerComponent implements OnInit, OnDestroy {
  @Input() session: ExamSession;
  @Input() exam: ExamBase;
  timerSub: Subscription;
  hidden = false;
  time;
  negative = false;
  storeKey;
  timerState;
  messageObserver;

  constructor(
    private utilityService: UtilityService,
    private messagingService: MessagingService,
    private storageService: LocalStorageService,
    private timerStateService: TimerStateService,
    private analyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    let questions = this.exam.getQuestions();

    this.storeKey = 'exam_timer_state:' + this.session.sid;
    this.timerState = this.storageService.getStoredValue(this.storeKey);
    if (this.timerState === null) {
      this.timerState = {
        totalTime: this.utilityService.getDefaultTimerLength(questions.length),
        timeUsed: 0,
        lastStarted: this.session.started,
        userPaused: false,
        isRunning: true
      };
    }

    this.messageObserver = this.messagingService.addObserver((message) => {
      if (message.messageType == 'exam-timer-set-state') {
        this.startStopTimer(message.data.running);
      }
    }, true);

    let timerObservable = timer(1000, 1000);
    this.timerSub = timerObservable.subscribe(t => {
      this.timerTick();
    });
  }

  /**
   * Callback for the timer.
   */
  timerTick() {
    // The total elapsed time during previous start/stops is stored in timeUsed. If the timer is running,
    // the time since the last timer start should also be counted.
    let elapsed = this.timerState.timeUsed;
    if (this.timerState.isRunning) {
      elapsed += this.utilityService.now() - this.timerState.lastStarted;
    }

    let left = this.timerState.totalTime - elapsed;

    // If timer is in negative more than 1h, just display -1h.
    if (left < -3600) {
      left = -3600;
    }

    this.time = this.utilityService.formatTime(left);
    this.negative = this.time.substr(0, 1) == '-';

    this.timerStateService.setState(this.session.sid, left);
  }

  /**
   * Hide timer button handler.
   */
  hideClicked() {
    this.hidden = !this.hidden;
  }

  /**
   * Starts/stops the timer.
   *
   * @param state
   *   If given, the state is set to the given running state. Otherwise it is flipped between paused started.
   * @param userAction
   *   Should be true if the action is taken by the user.
   *   If false, and the timer is stopped, it won't be started. That is, if the user purposely stopped the timer, it won't start.
   */
  startStopTimer(state = null, userAction = false) {
    if (state !== null) {
      if (this.timerState.isRunning == state) {
        // Already in the requested state.
        return;
      }

      if (this.timerState.userPaused && state == true) {
        // Trying to start, but the the user paused the timer. Skip.
        return;
      }
    }

    if (this.timerState.isRunning) {
      // The timer can be started/stopped multiple times, so we accumulate the total elapsed time
      // in timeUsed after each stop.
      let elapsed = this.timerState.timeUsed + this.utilityService.now() - this.timerState.lastStarted;
      this.timerState.timeUsed = elapsed;
      this.timerState.isRunning = false;
      this.analyticsService.sendEvent(EVENT_NAMES.QUIZ_TIMER_PAUSED);
    }
    else {
      this.timerState.lastStarted = this.utilityService.now();
      this.timerState.isRunning = true;
    }

    if (userAction) {
      // If the timer is stopped here, store that.
      // This is used to differentiate form automatic stops.
      this.timerState.userPaused = !this.timerState.isRunning;
    }

    // Update the display so that the next tick does not increase the timer in case timeUsed jumped since the last tick.
    this.timerTick();

    this.storageService.setStoredValue(this.storeKey, this.timerState);
  }

  ngOnDestroy() {
    this.timerSub.unsubscribe();
    if (this.messageObserver) {
      this.messageObserver.unsubscribe();
    }
  }

}
