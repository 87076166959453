import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-subject-outline',
  templateUrl: './subject-outline.component.html',
  styleUrls: ['./subject-outline.component.scss']
})
export class SubjectOutlineComponent implements OnInit {
  @Input() subjects;
  @Input() waqCount;
  @Input() defaultCheckboxState = false;
  @Output() selectionChanged = new EventEmitter();
  form;
  controls;
  openSubjects = {};
  formToggle;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    // The action that the "select/deselect all" link performs.
    this.formToggle = this.defaultCheckboxState ? 'none' : 'all';

    this.controls = {
      waq: false,
      all: this.defaultCheckboxState
    };

    // Create form component for each subject/section.
    // Note: the subjects input needs to be already loaded when this component is shown, so we can create the form here.
    this.subjects.forEach(subject => {
      this.addChildSubjectControls(subject);
    });

    this.form = this.fb.group(this.controls);

    // If the form values change, pass the values to the parent.
    this.form.valueChanges.subscribe(values => {
      this.selectionChanged.emit(values);
    });

    // Disable WAQ if user has no unanswered questions.
    if (!this.waqCount) {
      this.form.controls['waq'].disable();
    }

    // Send the initial value back to the parent.
    this.selectionChanged.emit(this.controls);
  }

  /**
   * Sets the form element for a subject and subsections.
   *
   * @param item
   *   Section or subject object.
   */
  addChildSubjectControls(item) {
    this.controls[item.nid] = this.defaultCheckboxState;

    if (item.sections) {
      item.sections.forEach(section => {
        this.addChildSubjectControls(section);
      });
    }
  }

  /**
   * Iterates through a section and its children, and sets the value of all checkboxes underneath.
   *
   * @param parent
   *   Parent item, section or subject.
   * @param values
   *   Form values containers, containing all values.
   * @param new_value
   *   Value to set to.
   *
   * @return
   *   Returns true if anything was changed.
   */
  toggleSections(parent, values, new_value) {
    let needsUpdate = false;
    if (!parent.sections) {
      return false;
    }

    parent.sections.forEach(section => {
      if (typeof values[section.nid] == 'undefined') {
        // This section is not shown in the form.
        return;
      }

      if (values[section.nid] !== new_value) {
        values[section.nid] = new_value;
        needsUpdate = true;
      }

      // Process child sections.
      if (section.sections) {
        let childNeedsUpdate = this.toggleSections(section, values, new_value);
        needsUpdate = needsUpdate || childNeedsUpdate;
      }
    });

    return needsUpdate;
  }

  /**
   * Iterates the section tree, and looks for the section whose checkbox was just changed. Flips the checkboxes on the parents if
   * anything is unchecked within the child tree.
   *
   * @param parent
   *   Parent element, section or subject.
   * @param nid
   *   The nid of the subject that was just changed.
   * @param values
   *   Value to set to.
   *
   * @return
   *   Object with:
   *   found: the nid was found within the subtree of parent.
   *   hasUnselected: parent or its subtree has at least one unchecked section
   *   needsUpdate: at least one section has been changed (to match the selection of the child tree)
   */
  updateSectionTree(parent, nid, values): any {
    let state = {
     found: false,
     hasUnselected: false,
     needsUpdate: false
    };

    if (!parent.sections) {
      return false;
    }

    parent.sections.forEach(section => {
      if (section.nid === nid) {
        // This is the section whose checkbox was changed.
        state.found = true;

        // Update all children to match the parent.
        let childNeedsUpdate = this.toggleSections(section, values, values[nid]);
        state.needsUpdate = state.needsUpdate || childNeedsUpdate;
      }

      // Check if the changed section is within the child sections.
      let childReturn = this.updateSectionTree(section, nid, values);
      state.found = state.found || childReturn.found;
      state.hasUnselected = state.hasUnselected || childReturn.hasUnselected;
      state.needsUpdate = state.needsUpdate || childReturn.needsUpdate;

      if (!values[section.nid]) {
        state.hasUnselected = true;
      }
    });

    if (state.found && state.hasUnselected) {
      // If there is anything unchecked under the section we changed, uncheck the subject itself.
      values[parent.nid] = false;
      state.needsUpdate = true;
    }

    return state;
  }

  /**
   * Called when a checkbox is changed.
   * Syncs subject selection with section selections. Checking the subject makes all the sections
   * underneath match the subject checkbox.
   * When a section is clicked, then the subject is unchecked if there is any unchecked section under
   * it (because checking the subject automatically includes all questions from the child sections).
   *
   * When the Wrong Answer Quiz is selected, all sections/subjects are unselected, and when any other
   * selection is made, the WAQ is unselected.
   *
   * @param nid
   *   The nid of the subject/section that is being toggled
   *   or all/none if the "select all/none" was clicked,
   *   or 'waq' if the Wrong Answer Quiz was selected.
   */
  onChange(nid) {
    let values = this.form.value;
    let needsUpdate = false;

    // Set the toggle link to "select none" when everything is checked.
    // and to "select all" when everything is unchecked.
    if (nid === 'all') {
      this.formToggle = 'none';
    }
    else if (nid === 'none' || nid === 'waq') {
      // Note we are toggling "select all/none" when WAQ is changed.
      this.formToggle = 'all';
    }

    this.subjects.forEach(subject => {
      if (nid !== 'waq') {
        // Selecting anything else disables WAQ.
        values['waq'] = false;
        needsUpdate = true;
      }

      if (nid === 'all' || nid === 'none' || nid === 'waq') {
        // Either select all/none is clicked, or WAQ is toggled. All sections will be set to the same state.
        let allState;
        if (nid === 'waq') {
          // Unselect/select all sections when WAQ is selected/unselected.
          allState = !values['waq'];
        }
        else {
          allState = (nid === 'all' ? true : false);
        }

        let childNeedsUpdate = this.toggleSections(subject, values, allState);
        needsUpdate = needsUpdate || childNeedsUpdate;

       if (values[subject.nid] !== allState) {
          values[subject.nid] = allState;
          needsUpdate = true;
        }
      }
      else if (subject.nid === nid) {
        // This section was changed, toggle all children to match the parent.
        let childNeedsUpdate = this.toggleSections(subject, values, values[nid]);
        needsUpdate = needsUpdate || childNeedsUpdate;
      }
      else {
        // One of the subsections was changed. Update the tree parents.
        let treeReturn = this.updateSectionTree(subject, nid, values);
        needsUpdate = needsUpdate || treeReturn.needsUpdate;
      }

    });

    // Only update once to avoid recursion.
    if (needsUpdate) {
      this.form.setValue(values);
    }

  }

  /**
   * Shows/hides a section tree.
   * @param nid
   */
  toggleSubject(nid) {
    this.openSubjects[nid] = !this.openSubjects[nid];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.form) {
      // This may trigger before init.
      return;
    }

    if (changes.waqCount) {
      if (changes.waqCount.currentValue) {
        this.form.controls['waq'].enable();
      }
      else {
        this.form.controls['waq'].disable();
      }
    }
  }
}
