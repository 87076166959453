import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {PracticeExam} from '../../model/practice-exam';
import {ExamSession} from '../../model/exam-session';
import {UtilityService} from '../../services/utility.service';
import {environment} from '../../../environments/environment';
import * as moment from "moment";
import {TourServiceService} from '../../services/tour-service.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-practice-exam',
  templateUrl: './practice-exam.component.html',
  styleUrls: ['./practice-exam.component.scss']
})
export class PracticeExamComponent implements OnInit, OnDestroy {
  currentDate;
  exam: PracticeExam;
  session: ExamSession;
  paramObserver;
  isLoading = false;
  showQuestionMeta = !environment.production;
  shownQuestion;

  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService,
    private router: Router,
    private route: ActivatedRoute,
    public tourService: TourServiceService,
    private analyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.currentDate = moment().format('D MMM YYYY');

    // Read the route parameters. The exam is not shown until these are available.
    this.paramObserver = this.route.params.subscribe(params => {
      this.isLoading = true;

      this.apiService.getExamSession(params['sid'], false, false).subscribe(
        result => {
          this.isLoading = false;
          this.exam = result['exam'];
          this.session = result['session'];

          if (this.session.hasEnded()) {
            this.router.navigate(['/official/results/' + this.session.sid]);
          }
        },
        error => {
          this.isLoading = false;
          if (error.status == 403) {
            this.utilityService.openModal('Access Denied', 'You do not have access to this session.');
          }
          else {
            this.utilityService.openModal('Error', 'An error occurred while loading this page.');
          }
        });
    });
  }

  /**
   * Triggered when an answer is submitted in the question component.
   */
  onQuestionSubmitted(data) {
    // Update the local session with the newly answered question (needed to update the question overview block)
    this.session = data;
  }

  /**
   * Ends the session.
   */
  endSession() {
    this.isLoading = true;

    this.apiService.endSession(this.session.sid).subscribe(
      result => {
        this.isLoading = false;
        this.router.navigate(['/practice/results/' + this.session.sid]);
      },
      error => {
        this.isLoading = false;
        this.utilityService.openModal('Error', 'We were unable to submit your results. Please try again.');
      });
  }

  /**
   * Called when the question changes.
   */
  onQuestionShown(value) {
    this.shownQuestion = value;
    this.analyticsService.sendCustomPageViewEvent(this.router.url, `MBE Quiz Maker | ${value.question_id}`);
  }

  ngOnDestroy() {
    this.paramObserver.unsubscribe();
  }

}
