import {BaseModel} from './base-model';
import * as moment from 'moment';
import {OfficialExam} from './official-exam';
import {PracticeExam} from './practice-exam';
import {ExamBase} from './exam-base';

export class ExamSession extends BaseModel {
  sid = null;
  exam_type = null;
  exam_id = null;
  started = null;
  ended = null;
  uid = null;
  answers: [any] = null;
  flagged_questions: any[] = null;
  avg_answer_time = null;
  total_test_time = null;
  total_questions = null;
  correct_answers = null;
  correct_percentage = null;
  exam: ExamBase = null;

  constructor(data) {
    super();
    this.copyData(data);

    if (this.exam) {
      // If the corresponding exam was included in the data, turn that into an object.
      this.exam = this.exam_type == 'O' ? new OfficialExam(this.exam) : new PracticeExam(this.exam);
    }

    if (!this.flagged_questions) {
      this.flagged_questions = new Array<any>();
    }
  }

  /**
   * Returns the answer for a given question in this session.
   *
   * @param nid
   *   Question node id.
   */
  getAnswerForQuestion(nid) {
    let answer = null;

    if (this.answers) {
      this.answers.forEach(item => {
        if (item.question_nid == nid) {
          answer = item.answer;
        }
      });
    }

    return answer;
  }

  /**
   * Checks if this session has the correct answer for a given question.
   *
   * @param nid
   *   Question node id.
   */
  hasCorrectAnswerForQuestion(nid) {
    let correct = false;

    if (this.answers) {
      this.answers.forEach(item => {
        if (item.question_nid == nid) {
          correct = item.correct;
        }
      });
    }

    return correct;
  }

  /**
   * Returns an array of question nids for which this session stores an answer.
   */
  getAnsweredNids() {
    let answered = [];
    if (this.answers) {
      this.answers.forEach(item => {
        if (item.answer !== null) {
          answered.push(item.question_nid);
        }
      });
    }

    return answered;
  }

  /**
   * Checks if this session has ended.
   */
  hasEnded() {
    return this.ended && parseInt(this.ended) > 0;
  }

  /**
   * Formats the start time.
   *
   * @param format
   *   Moment.js format string.
   */
  formattedStart(format) {
    return this.started ? moment.unix(this.started).format(format) : '';
  }

  /**
   * Formats the timestamp for a flagged question.
   *
   * @param format
   *   Moment.js format string.
   * @param flag
   *   Flag record.
   */
  formattedFlagTime(format, flag) {
    return flag.timestamp ? moment.unix(flag.timestamp).format(format) : '';

  }

  /**
   * Flags/unflags a question.
   *
   * @param nid
   * @param state
   */
  flagQuestion(nid, state) {
    if (state) {
      this.flagged_questions.push({question_nid: nid});
    }
    else {
      this.flagged_questions = this.flagged_questions.filter(item => item.question_nid != nid);
    }
  }

  /**
   * Checks if a q is flagged in this session.
   * @param nid
   */
  isQuestionFlagged(nid) {
    let flagged = false;
    if (!this.flagged_questions) {
      return false;
    }

    this.flagged_questions.forEach((item) => {
      if (item.question_nid == nid) {
        flagged = true;
      }
    });

    return flagged;
  }
}
