import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-classroom-tabs',
  templateUrl: './classroom-tabs.component.html',
  styleUrls: ['./classroom-tabs.component.scss']
})
export class ClassroomTabsComponent implements OnInit {
  @Input()
  activeComponent;

  @Input()
  subject;

  @Input()
  studentType;

  constructor() { }

  ngOnInit() {
  }

}
