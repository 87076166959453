import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AccordionComponent, AccordionPanelComponent} from 'ngx-bootstrap';
import {MessagingService} from '../../services/messaging.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {ActivatedRoute} from '@angular/router';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-classroom-essays',
  templateUrl: './classroom-essays.component.html',
  styleUrls: ['./classroom-essays.component.scss']
})
export class ClassroomEssaysComponent implements OnInit, OnChanges {
  isP0Open: boolean = false;
  isP1Open: boolean = false;
  isP2Open: boolean = false;
  isP3Open: boolean = false;
  messageObserver;

  @Input()
  subject;

  @Input()
  activeEssay;

  @Input()
  activeEssayStudentData;

  @Input()
  studentData;

  @Output()
  nextEssayClickEmitted = new EventEmitter();

  @Output()
  previousEssayClickEmitted = new EventEmitter();

  @Output()
  navigateToROEEmitted = new EventEmitter();

  @Output()
  studentDataChangedEmitted = new EventEmitter<object>();

  constructor(@Inject(DOCUMENT) private document: Document,
              private activatedRoute: ActivatedRoute,
              private messagingService: MessagingService,
              private storageService: LocalStorageService) { }

  ngOnInit() {
    this.messageObserver = this.messagingService.addObserver((message => {
      if (message.messageType == 'scroll-to-content') {
        if (message.data == 'video') {
          this.openAccordion(0);
        }
        else if (message.data == 'breakdown') {
          this.openAccordion(1);
        }
        else if (message.data == 'model-answer') {
          this.openAccordion(2);
        }
        else if (message.data == 'board-answer') {
          this.openAccordion(3);
        }
        else if (message.data == 'audio' && this.activeEssay) {
          this.startAudio(this.activeEssay.nid);
        }
      }
    }), true);
  }

  ngOnChanges(SimpleChanges) {
    // Set accordion to close on each essay section change.
    if (SimpleChanges.activeEssay) {
      this.isP0Open = false;
      this.isP1Open = false;
      this.isP2Open = false;
      this.isP3Open = false;
    }
  }

  /**
   * Click handler for essay Next button.
   */
  nextEssayClickedEmit() {
    this.nextEssayClickEmitted.emit();
  }

  /**
   * Click handler for essay Previous button.
   */
  previousEssayClickedEmit() {
    this.previousEssayClickEmitted.emit();
  }

  /**
   * Click handler for essay ROE link.
   */
  navigateToROEEmit(event) {
    this.navigateToROEEmitted.emit(event);
  }

  /**
   * Click handler for essay mark as complete button.
   */
  studentDataChangedEmit(event, nid, complete) {
    let params = {
      nid: nid,
      complete: complete ? 1 : 0,
    }
    this.studentDataChangedEmitted.emit(params);
  }

  /**
   * Click handler for audio file play.
   */
  startAudio(nid) {
    let params = {
      audio_id: nid,
      playlist: this.subject.essays
    };

    this.messagingService.sendMessage('start-audio', params);
  }

  /**
   * Opens the an accordion and scrolls to it.
   *
   * Accordion ids are:
   * 0: Video
   * 1: Critical Breakdown
   * 2: Model Answer by One-Timers
   * 3: Answer Released by Board
   */
  openAccordion(id) {
    let elemId;

    if (id == 0) {
      this.isP0Open = true;
      elemId = '#accordion-p0';
    }
    else if (id == 1) {
      this.isP1Open = true;
      elemId = '#accordion-p1';
    }
    else if (id == 2) {
      this.isP2Open = true;
      elemId = '#accordion-p2';
    }
    else if (id == 3) {
      this.isP3Open = true;
      elemId = '#accordion-p3';
    }
    else {
      return;
    }

    let elem = this.document.querySelector(elemId);
    if (elem) {
      elem.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }

  ngOnDestroy() {
    if (this.messageObserver) {
      this.messageObserver.unsubscribe();
    }
  }

}
