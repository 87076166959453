import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilityService} from '../../services/utility.service';
import * as moment from "moment";
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-practice-results',
  templateUrl: './practice-results.component.html',
  styleUrls: ['./practice-results.component.scss']
})
export class PracticeResultsComponent implements OnInit, OnDestroy {
  currentDate;
  session_id;
  session;
  exam;
  results;
  paramSubscription;
  sessionLoader;

  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService,
    private router: Router,
    private route: ActivatedRoute,
    private analyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.currentDate = moment().format('D MMM YYYY');

    // Read the route parameters. The exam is not shown until these are available.
    this.paramSubscription = this.route.params.subscribe(params => {
      this.session_id = params['sid'];

      this.sessionLoader = this.apiService.getExamSession(this.session_id, true);

      this.sessionLoader.subscribe(result => {
        if (result) {
          this.exam = result['exam'];
          this.session = result['session'];
        }
      },
      error => {
        if (error.status == 403) {
          this.utilityService.openModal('Access Denied', 'You do not have access to this session.');
        }
        else {
          this.utilityService.openModal('Error', 'An error occurred while loading this page.');
        }
      });
    });

    this.analyticsService.sendCustomPageViewEvent(this.router.url, 'MBE Quiz Maker | Results');
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
  }

}
