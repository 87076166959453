import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {ClassroomComponent} from './pages/classroom/classroom.component';
import {ClassroomSubjectComponent} from './pages/classroom-subject/classroom-subject.component';
import {PracticeComponent} from './pages/practice/practice.component';
import {PracticeExamComponent} from './pages/practice-exam/practice-exam.component';
import {PracticeResultsComponent} from './pages/practice-results/practice-results.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {OfficialExamComponent} from './pages/official-exam/official-exam.component';
import {OfficialComponent} from './pages/official/official.component';
import {OfficialResultsComponent} from './pages/official-results/official-results.component';
import {AssignmentsComponent} from './pages/assignments/assignments.component';
import {MessagesComponent} from './pages/messages/messages.component';
import {LiveClassesComponent} from "./pages/live-classes/live-classes.component";
import {ResourcesComponent} from "./pages/resources/resources.component";
import {AuthGuardService} from './services/auth-guard.service';
import {ResultsComponent} from './pages/results/results.component';
import {SearchComponent} from './pages/search/search.component';
import {JoyrideModule} from 'ngx-joyride';
import {EssayChartComponent} from './pages/essay-chart/essay-chart.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'subjects', component: ClassroomComponent, canActivate: [AuthGuardService] },
  { path: 'subjects/subject/:nid/:activeComponent', component: ClassroomSubjectComponent, canActivate: [AuthGuardService] },
  { path: 'practice', component: PracticeComponent, canActivate: [AuthGuardService] },
  { path: 'practice/exam/:sid', component: PracticeExamComponent, canActivate: [AuthGuardService] },
  { path: 'practice/results/:sid', component: PracticeResultsComponent, canActivate: [AuthGuardService] },
  { path: 'official', component: OfficialComponent, canActivate: [AuthGuardService] },
  { path: 'official/exam/:sid', component: OfficialExamComponent, canActivate: [AuthGuardService] },
  { path: 'official/results/:sid', component: OfficialResultsComponent, canActivate: [AuthGuardService] },
  { path: 'assignments', component: AssignmentsComponent, canActivate: [AuthGuardService] },
  { path: 'messages', component: MessagesComponent, canActivate: [AuthGuardService] },
  { path: 'live-classes', component: LiveClassesComponent, canActivate: [AuthGuardService] },
  { path: 'resources', component: ResourcesComponent, canActivate: [AuthGuardService] },
  { path: 'results', component: ResultsComponent, canActivate: [AuthGuardService] },
  { path: 'search', component: SearchComponent, canActivate: [AuthGuardService] },
  { path: 'essay-chart', component: EssayChartComponent, canActivate: [AuthGuardService] },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [    JoyrideModule.forRoot(),
    RouterModule.forRoot(routes, { useHash: true })],
  exports: [JoyrideModule, RouterModule]
})
export class AppRoutingModule { }
