import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {UtilityService} from '../../services/utility.service';
import {environment} from '../../../environments/environment';
import * as moment from "moment";
import {TourServiceService} from '../../services/tour-service.service';
import {UserInfo} from '../../model/user-info';
import {PracticeExam} from '../../model/practice-exam';
import { EVENT_NAMES, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['./practice.component.scss']
})
export class PracticeComponent implements OnInit {
  currentDate;
  subjects;
  numOfQuestions = 100;
  useTimer = 0;
  showTimer = 0;
  showAnswers = 1;
  nonRandom = 1;
  excludeOfficial = 0;
  includeSeen = 1;
  wrongAnswerQuiz = 0;
  selectedSections = [];
  isLoading = false;
  selectedOptions;
  userInfo;

  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService,
    private router: Router,
    public tourService: TourServiceService,
    private analyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.currentDate = moment().format('D MMM YYYY');

    this.apiService.getUserInfo(true).subscribe((userInfo: UserInfo) => {
      this.userInfo = userInfo;
    });

    this.apiService.getSubjects(false, true).subscribe(result => {
      if (result) {
        // Note, once loaded, subjects cannot be changed because the subject outline component won't rerender.
        this.subjects = result;
      }
    });

    this.analyticsService.sendCustomPageViewEvent(this.router.url, 'MBE Quiz Maker | Settings');
  }

  /**
   * Callback for when the selection changes in the subject outline component.
   */
  sectionsChanged(data) {
    this.selectedSections = [];
    this.wrongAnswerQuiz = 0;

    if (data['waq']) {
      // Wrong Answer Quiz is selected (section selections are irrelevant).
      this.wrongAnswerQuiz = 1;
      this.includeSeen = 1; // Will be disabled
      return;
    }

    // The keys on data are form field ids, which are nids. Value is true if the checkbox is checked.
    // Find the checked ones.
    Object.keys(data).forEach(key => {
      if (key === 'all' || key === 'waq') {
        // Ignore the state of the 'all' and 'waq' checkbox.
        return;
      }

      if (data[key]) {
        this.selectedSections.push(key);
      }
    });
  }

  /**
   * Click handler for the button on the options screen.
   */
  saveOptionsClicked() {
    let number = Number(this.numOfQuestions);
    let limit = !environment.production ? 1000 : 100;

    if (isNaN(number) || number < 1 || number > limit) {
      this.utilityService.openModal('Invalid number', 'Please enter a number between 1 and ' + limit + '.');
      return;
    }

    window.scroll(0,0);

    this.selectedOptions = {
      'use_timer': this.useTimer,
      'show_timer': this.showTimer,
      'show_answers': this.showAnswers,
      'non_random': this.nonRandom,
      'exclude_official': this.excludeOfficial,
      'include_seen': this.includeSeen,
      'num_of_questions': this.numOfQuestions,
      'waq': this.wrongAnswerQuiz,
      'selected_sections': this.selectedSections
    };

    // send GA events
    this.analyticsService.sendEvent(
      EVENT_NAMES.MBE_QUIZ_MAKER_QUESTION_COUNT,
      this.numOfQuestions.toString()
    );

    const selectedPreferenceStrings = [];
    if (!!this.selectedOptions.non_random) selectedPreferenceStrings.push('non random');
    if (!!this.selectedOptions.show_answers) selectedPreferenceStrings.push('show answers');
    if (!!this.selectedOptions.exclude_official) selectedPreferenceStrings.push('exclude official');
    if (!!this.selectedOptions.include_seen) selectedPreferenceStrings.push('include answered');
    this.analyticsService.sendEvent(
      EVENT_NAMES.MBE_QUIZ_MAKER_PREFERENCES,
      (!!selectedPreferenceStrings.length) ? selectedPreferenceStrings.join(', ') : 'none'
    )

    this.analyticsService.sendCustomPageViewEvent(this.router.url, 'MBE Quiz Maker | Instructions');
  }

  /**
   * Click handler for the on the start exam button.
   */
  startExamClicked() {
    this.isLoading = true;

    // Start an exam session, the redirect to the exam taking page.
    this.apiService.startPractice(this.selectedOptions).subscribe(
      result => {
        this.isLoading = false;
        this.router.navigate(['/practice/exam/' + result['sid']]);
      },
      error => {
        this.isLoading = false;

        // Revert to the options page.
        this.selectedOptions = null;

        if (error.error_code === 'no_questions') {
          this.utilityService.openModal('Error', 'No questions are available with these selections. Please try again.');
        }
        else {
          this.utilityService.openModal('Error', 'We were unable to start your session. Please try again.');
        }
      });
  }

  /**
   * Click handler for the reset button.
   */
  resetClicked() {
    let message = '<p>Are you sure you want to reset your MBE analytics? This will permanently delete:</p>' +
      '<ul>' +
      '<li>All of your performance scores in the Dashboard</li>' +
      '<li>All of your Results on the Results page</li>' +
      '<li>All record of what MBE questions you got right or wrong, including Official Practice Exam questions</li>' +
      '</ul>' +
      '<p>This cannot be undone.</p>';

    let buttons = [
      {text: 'Yes, reset my MBE analytics', type: 'danger', callback: () => { this.resetExamData() }},
      {text: 'Cancel', type: 'primary', cancel: true},
    ];

    this.utilityService.openModal('Are you sure?', message, null, buttons);
  }

  /**
   * Resets exam data.
   */
  resetExamData() {
    this.isLoading = true;
    this.apiService.resetExamData().subscribe(
      result => {
        this.isLoading = false;
        this.utilityService.openModal('Success', 'Your MBE analytics have been reset.');

        // Reload waq count.
        this.apiService.getUserInfo(true).subscribe((userInfo: UserInfo) => {
          this.userInfo = userInfo;
        });
      },
      error => {
        this.isLoading = false;
        this.utilityService.openModal('Error', 'We were unable to process your request. Please try again.');
      });
  }

}
