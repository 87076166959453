import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ExamSession} from '../../model/exam-session';
import {ExamBase} from '../../model/exam-base';
import {LocalStorageService} from '../../services/local-storage.service';
import {UtilityService} from '../../services/utility.service';
import {PracticeExam} from '../../model/practice-exam';
import {environment} from '../../../environments/environment';
import {MessagingService} from '../../services/messaging.service';
import {switchMap} from 'rxjs/operators';
import {empty} from 'rxjs';
import {TourServiceService} from '../../services/tour-service.service';
import { TimerStateService } from 'src/app/services/timer-state.service';

@Component({
  selector: 'app-exam-question',
  templateUrl: './exam-question.component.html',
  styleUrls: ['./exam-question.component.scss']
})
export class ExamQuestionComponent implements OnInit, OnDestroy {
  @Input() session: ExamSession;
  @Input() exam: ExamBase;
  @Output() submitted = new EventEmitter();
  @Output() passed = new EventEmitter();
  @Output() questionShown = new EventEmitter();
  @Output() finished = new EventEmitter(); // Triggered after the last question has been submitted.
  questionIndex = 0;
  question;
  isLoading = false;
  selectedAnswer = null;
  hasAnswer = false;
  correctAnswerShowing = false;
  questionFlagged = false;
  reveal = !environment.production;
  answerStates;
  queryObserver;
  questionTimerStart;
  storeKey;
  messageObserver;

  constructor(private apiService: ApiService,
              private storageService: LocalStorageService,
              public utilityService: UtilityService,
              public messagingService: MessagingService,
              private route: ActivatedRoute,
              private router: Router,
              public tourService: TourServiceService,
              private timerStateService: TimerStateService) { }

  ngOnInit() {

    this.queryObserver = this.route.queryParams.pipe(switchMap(query => {
      return this.showQuestion(query['q'] ? parseInt(query['q']) : 0);
    }))
    .subscribe(data => {
      // New question data was loaded.
      this.exam.mergeData(data);

      // Update this.question.
      this.question = this.exam.getQuestion(this.questionIndex);
    });

    this.messageObserver = this.messagingService.addObserver((message) => {
      if (message.messageType == 'exam-question-should-finish') {
        this.shouldFinish();
      }
    }, true);

    this.storeKey = 'exam_question_answer_state:' + this.session.sid;
    this.answerStates = this.storageService.getStoredValue(this.storeKey, {hidden: {}, starred: {}});
  }

  /**
   * Shows the given question.
   * Loads the question data if needed. Returns an observable that emits the newly loaded question, if it was loaded.
   */
  showQuestion(index) {
    this.questionIndex = index;
    this.question = this.exam.getQuestion(this.questionIndex);
    this.hasAnswer = false;
    this.correctAnswerShowing = false;
    const timeLeft = this.timerStateService.getState(this.session.sid);
    this.questionTimerStart 
      = (!!timeLeft) 
        ? timeLeft 
        : this.utilityService.getDefaultTimerLength(this.exam.questions.length);
    this.questionFlagged = this.question ? this.session.isQuestionFlagged(this.question.question_nid) : false;

    this.messagingService.sendMessage('exam-timer-set-state', {running: true});

    // Update the selected answer to match the new question.
    if (this.question) {
      this.selectedAnswer = this.session.getAnswerForQuestion(this.question.question_nid);
      if (this.selectedAnswer !== null) {
        this.hasAnswer = true;
      }
    }
    else {
      this.selectedAnswer = null;
    }

    this.questionShown.emit(this.question);

    if (this.question && this.question.content_loaded === false) {
      return this.apiService.getExamQuestion(this.session.sid, this.question.question_nid);
    }
    else {
      return empty();
    }
  }

  /**
   * Called when the user selects a question.
   */
  onAnswerSelected(pid) {
    if (this.correctAnswerShowing) {
      // Ignore clicks when the correct answers are shown.
      return;
    }

    this.selectedAnswer = pid;
  }

  /**
   * Called when the user initiates to finish the exam (via the button in the exam-question-overview component),
   * or the last question is answered.
   */
  shouldFinish() {
    let nextUnanswered = this.exam.findNextUnansweredQuestion(this.session, -1);
    let t;

    if (nextUnanswered === null) {
      t = 'All questions have been answered. Click "Finish and View Results" to end the session, or click on question numbers to review your answers first.';
    }
    else {
      t = 'You have not answered all questions yet. Click "Continue" to continue to answer questions or click "Finish and View Results" to end the session.';
    }

    let buttons = [
      {text: 'Finish and View Results', type: 'danger', callback: () => { this.finished.emit() }},
      {text: 'Continue', type: 'primary', cancel: true},
    ];

    this.utilityService.openModal('Finished?', t, null, buttons);
  }

  /**
   * Step to the next question if there are more, or jumps back to the next unanswered question
   * if we are on the last question.
   * Triggers the finished event otherwise if we are on the last question and there are no
   * unanswered ones.
   */
  jumpToNext() {
    let nextUnanswered = this.exam.findNextUnansweredQuestion(this.session, this.questionIndex);

    if (nextUnanswered === null) {
      this.shouldFinish();
    }
    else if (this.questionIndex < this.exam.questionCount() - 1) {
      // Just update the route here. This will trigger showQuestion().
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { q: this.questionIndex + 1 },
      });
    }
    else {
      // On the last question, loop back to the first unanswered one.
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {q: nextUnanswered},
      });
    }
  }

  /**
   * Called when the submit button is pressed.
   * Submits the answer to the api, and if that's successful, shows the next question.
   * If there are no more questions left, triggers the finished event.
   */
  onSubmit() {
    const questionTimerEnd = this.timerStateService.getState(this.session.sid);
    const time = this.questionTimerStart - questionTimerEnd;
    this.isLoading = true;

    this.apiService.submitAnswer(this.session.sid, this.question.question_nid, this.selectedAnswer, time).subscribe(
      result => {
        this.isLoading = false;

        // The api returns a fresh session object. Replace our copy so that it has the new answer.
        this.session = result['session'];
        this.submitted.emit(this.session);

        if (this.exam instanceof PracticeExam && this.utilityService.toBool(this.exam.show_answers)) {
          window.scroll(0,0);
          this.correctAnswerShowing = true;
          this.messagingService.sendMessage('exam-timer-set-state', {running: false});
        }
        else {
          this.jumpToNext();
        }
      },
      error => {
        this.isLoading = false;

        this.utilityService.openModal('Error', 'We were unable to submit your answer. Please try again.');
      });
  }

  /**
   * Called when the user passes the question.
   */
  onPass() {
    this.passed.emit();

    let nextUnanswered = this.exam.findNextUnansweredQuestion(this.session, this.questionIndex);
    if (nextUnanswered !== null) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {q: nextUnanswered},
      });
    }
  }

  /**
   * Click handler for the "next question" button, which is shown when the question is already submitted
   * and the correct answer is showing.
   */
  nextQuestionClicked() {
    this.correctAnswerShowing = false;
    this.jumpToNext();
  }

  /**
   * Toggles the state of an answer.
   *
   * @param pid
   *   Answer id.
   * @param type
   *   State type, e.g. starred, hidden.
   */
  toggleAnswerState(pid, type) {
    this.answerStates[type][pid] = this.answerStates[type][pid] ? !this.answerStates[type][pid] : true;
    this.storageService.setStoredValue(this.storeKey, this.answerStates);
  }

  /**
   * Called when the flag is clicked.
   */
  toggleFlaggedQuestion() {
    this.questionFlagged = !this.questionFlagged;
    this.session.flagQuestion(this.question.question_nid, this.questionFlagged);

    this.apiService.flagQuestion(this.session.sid, this.question.question_nid, this.questionFlagged).subscribe(
      result => {
      },
      error => {
        this.utilityService.debugLog('Question flagging failed.');
      });
  }

  /**
   * Checks the state of an answer.
   *
   * @param pid
   *   Answer id.
   * @param type
   *  State to check.
   */
  checkAnswerState(pid, type) {
    return this.answerStates[type][pid] ? this.answerStates[type][pid] : false;
  }

  ngOnDestroy() {
    this.queryObserver.unsubscribe();
    if (this.messageObserver) {
      this.messageObserver.unsubscribe();
    }
  }

}
