import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ElementRef,
  ViewChild, HostListener, AfterViewInit, Renderer2
} from '@angular/core';
import {MessagingService} from '../../services/messaging.service';

@Component({
  selector: 'app-classroom-outline-navigation',
  templateUrl: './classroom-outline-navigation.component.html',
  styleUrls: ['./classroom-outline-navigation.component.scss']
})
export class ClassroomOutlineNavigationComponent implements OnInit, OnChanges {

  @ViewChild('stickyMenu', {static: true}) menuElement: ElementRef;

  controls = {};
  activeAudio = null;
  sticky: boolean = false;
  // elementPosition: any;
  // elementHeight: any;
  // elementWidth: any;

  @Input()
  subject;

  @Input()
  flattenedSubject;

  @Input()
  activeSection;

  @Input()
  studentData;

  @Output()
  sectionNavEmitted = new EventEmitter<string>();

  @Output()
  studentDataChangedEmitted = new EventEmitter<object>();

  constructor(
    private messagingService: MessagingService
  ) { }

  ngOnInit() {
    this.updateControls(this.studentData);
  }

  // ngAfterViewInit(){
  //   this.elementPosition = this.menuElement.nativeElement.offsetTop;
  //   this.elementHeight = this.menuElement.nativeElement.clientHeight;
  //   this.elementWidth = this.menuElement.nativeElement.clientWidth;
  // }

  // @HostListener('window:scroll', ['$event'])
  //   handleScroll(){
  //     const windowScroll = window.pageYOffset;
  //     if (windowScroll >= this.elementPosition) {
  //       this.sticky = true;
  //       this.renderer.setStyle(this.menuElement.nativeElement, 'height', this.elementHeight);
  //       this.renderer.setStyle(this.menuElement.nativeElement, 'width', this.elementWidth);
  //     }
  //     else {
  //       this.sticky = false;
  //       this.renderer.setStyle(this.menuElement.nativeElement, 'height', 'auto');
  //       this.renderer.setStyle(this.menuElement.nativeElement, 'width', 'auto');
  //     }
  //   }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.studentData && changes.studentData.currentValue) {
      this.updateControls(changes.studentData.currentValue);
    }
  }

  /**
   * Updates navigation based on student's completion data.
   */
  updateControls(changes) {
    this.flattenedSubject.forEach(subject => {
      this.controls[subject.nid] = false;
      changes.forEach(dataPoint => {
        if (dataPoint.nid == subject.nid && dataPoint.complete == true) {
          this.controls[subject.nid] = true;
        }
      });
    });
  }

  /**
   * Click handler for section navigation link.
   */
  navigateToSectionEmit(event, sectionNid) {
    this.sectionNavEmitted.emit(sectionNid);
  }

  /**
   * Click handler for section navigation checkbox.
   */
  studentDataChangedEmit(event, nid, complete) {
    let params = {
      nid: nid,
      complete: complete ? 1 : 0,
    }
    this.studentDataChangedEmitted.emit(params);
  }

  /**
   * Checks if the give section has audio chapters.
   */
  hasAudio(section) {
    return section.audio_chapters && section.audio_chapters.length > 0;
  }

  /**
   * Click handler for audio file play.
   */
  startAudio(section) {
    let params = {
      playlist: section.audio_chapters
    }
    this.activeAudio = section.nid;

    this.messagingService.sendMessage('start-audio', params);
  }

}
