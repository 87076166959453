import {AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AnnotatorService} from '../services/annotator.service';
import {MessagingService} from '../services/messaging.service';
import {LocalStorageService} from '../services/local-storage.service';
import {Subscription} from 'rxjs';

declare var jQuery: any;

@Directive({
  selector: '[appAnnotator]'
})
export class AnnotatorDirective implements OnDestroy, AfterViewInit, OnChanges, OnInit, OnDestroy {
  // The node id that this annotator belongs to.
  @Input() nid: number;
  // A section name, in case there are multiple annotator instances for one node (e.g. body, or a field name)
  @Input() segment: string;
  enabled;
  messageObserver: Subscription;
  viewInit = false;
  annotatorInit = false;
  elem;

  constructor(private el: ElementRef,
              private messagingService: MessagingService,
              private storageService: LocalStorageService,
              private annotatorService: AnnotatorService) {
    // AnnotatorService initializes the global Annotator object, so it needs to be loaded, even if unneeded.
    this.elem = jQuery(this.el.nativeElement);
  }

  ngOnInit() {
    this.enabled = this.storageService.getStoredValue('annotations-enabled');
    this.messageObserver = this.messagingService.addObserver((message) => {
      if (message.messageType == 'stored-value-changed' && message.data.key == 'annotations-enabled') {
        this.enabled = message.data.new_value;

        // Trigger the change logic manually (because .enabled is not an input so won't trigger it)
        this.ngOnChanges(null);
      }
    }, true);
  }

  /**
   * Initializes the Annotator plugin for this DOM element.
   * Note, the directive's content should contain only one child div with the text that is to be annotated, nothing else.
   * The annotations rely on the exact DOM structure of the content so if the template is changed, the annotations won't be accurate.
   *
   * Documentation:
   * http://docs.annotatorjs.org/en/v1.2.x/
   */
  initAnnotator() {
    if (this.annotatorInit || !this.enabled) {
      return;
    }

    this.elem.annotator();
    this.elem.annotator('addPlugin', 'Store', {
      annotationData: {
        nid: this.nid,
        segment: this.segment
      }
    });

    this.annotatorInit = true;
  }

  destroyAnnotator() {
    if (!this.annotatorInit) {
      return;
    }

    this.elem.annotator('destroy');
    this.annotatorInit = false;
  }

  ngAfterViewInit() {
    this.viewInit = true;
    this.initAnnotator();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.viewInit) {
      // Do not call before ngAfterViewInit because the content is not rendered yet.
      return;
    }

    // If nid or segment changes, we need to reinitialize because, the data within the annotator needs updating, and the content may
    // have changed, so the plugin needs to find the new content and load the relevant annotations.
    // Note: either the nid or the segment input should be changed from outside whenever the content withing the directive changes to
    // trigger the reloading.
    this.destroyAnnotator();
    this.initAnnotator();
  }

  ngOnDestroy() {
    if (this.messageObserver) {
      this.messageObserver.unsubscribe();
    }

    this.destroyAnnotator();
  }
}
