import {BaseModel} from './base-model';

export class ClassroomSubject extends BaseModel {
  title = null;
  nid = null;
  full_audio = null;
  full_essays_download = null;
  video_url = null;
  video_start = null;
  roe_video_url = null;
  image = null;
  roe_body = null;
  roe_title = null;
  sections = null;
  essays = null;

  constructor(data) {
    super();
    this.copyData(data);
  }

  /**
   * Finds an essay.
   *
   * @param nid
   */
  getEssay(nid) {
    if (!nid || !this.essays) {
      return null;
    }

    for (let i = 0; i < this.essays.length; i++) {
      if (this.essays[i].nid == nid) {
        return this.essays[i];
      }
    }
    return null;
  }

  /**
   * Finds a section.
   *
   * @param nid
   * @param search
   *   Array to search. Used internally for recursion.
   */
  getSubject(nid, search = null) {
    search = search || this.sections;

    if (!nid || !search) {
      return null;
    }

    for (let i = 0; i < search.length; i++) {
      if (search[i].nid == nid) {
        return search[i];
      }

      if (Array.isArray(search[i].sections)) {
        let result = this.getSubject(nid, search[i].sections);

        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  /**
   * Updates the values withing this subject from the data.
   *
   * @param data
   *   Section or essay data, coming from the api.
   */
  mergeData(data) {
    let item;
    if (!data.nid) {
      return;
    }

    // data could be either a subject or essay.
    item = this.getSubject(data.nid) || this.getEssay(data.nid);

    if (item) {
      Object.assign(item, data);
    }

    return item;
  }
}
