import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ExamBase} from '../../model/exam-base';
import {ActivatedRoute, Router} from '@angular/router';
import {ExamSession} from '../../model/exam-session';
import {MessagingService} from '../../services/messaging.service';
import {UtilityService} from '../../services/utility.service';
import {TourServiceService} from '../../services/tour-service.service';

@Component({
  selector: 'app-exam-question-overview',
  templateUrl: './exam-question-overview.component.html',
  styleUrls: ['./exam-question-overview.component.scss']
})
export class ExamQuestionOverviewComponent implements OnInit, OnDestroy {
  @Input() exam: ExamBase;
  @Input() session: ExamSession;
  @Input() isResult = false;
  currentQuestion = -1;
  queryObserver;

  constructor(private router: Router,
              private messagingService: MessagingService,
              private utilityService: UtilityService,
              private route: ActivatedRoute,
              public tourService: TourServiceService
  ) { }

  ngOnInit() {
    if (!this.isResult) {
      this.queryObserver = this.route.queryParams.subscribe(query => {
        this.currentQuestion = query['q'] ? parseInt(query['q']) : 0;
      });
    }
  }

  /**
   * Navigates to the clicked question.
   * Note, here we assume that the question navigation is based on the "q" query param, so
   * this method just updates that query param. The parents/siblings need to watch for changes
   * in the url. Otherwise, they may also observe the exam-question-clicked event.
   *
   * @param question
   *   Question object.
   * @param number
   *   Sequential question number.
   */
  numberClicked(question, number) {
    this.messagingService.sendMessage('exam-question-clicked', {number: number, question: question});

    if (this.isResult) {
      return;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { q: number },
    });
  }

  /**
   * Called when the finish button is clicked.
   */
  finishClicked() {
    // Ask the exam-question component to finish the exam.
    this.messagingService.sendMessage('exam-question-should-finish');
  }

  ngOnDestroy() {
    if (this.queryObserver) {
      this.queryObserver.unsubscribe();
    }
  }

}
