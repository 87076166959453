import {BaseModel} from './base-model';
import {ExamSession} from './exam-session';

export class ExamBase extends BaseModel {
  questions: [any] = null;

  getQuestions() {
    return this.questions ? this.questions : [];
  }

  questionCount() {
    return this.questions ? this.questions.length : 0;
  }

  /**
   * Finds a question by index.
   */
  getQuestion(i) {
    return (this.questions && this.questions[i]) ? this.questions[i] : null;
  }

  /**
   * Finds the index of a given question node.
   *
   * @param nid
   *   Question node id.
   */
  findQuestionIndex(nid) {
    if (!this.questions) {
      return null;
    }

    let index = this.questions.findIndex(item => { return parseInt(item.question_nid) == parseInt(nid)});
    return index >= 0 ? index : null;
  }

  /**
   * Finds a question by node id.
   *
   * @return
   *   Question object.
   */
  getQuestionByNid(nid) {
    let found = null;
    if (!this.questions) {
      return null;
    }

    this.questions.forEach(question => {
      if (question.question_nid == nid) {
        found = question;
      }
    });

    return found;
  }

  getId() {
    // To be overridden by child.
  }

  /**
   * Finds the index of the next question that is unanswered (used when passing a question)
   *
   * @param session
   *   Session containing the answers.
   * @param index
   *   The current question index, relative to which we are searching. This question is excluded from the check. Use -1 to check all questions.
   */
  findNextUnansweredQuestion(session: ExamSession, index) {
    let answered = session.getAnsweredNids();
    let nextUnanswered = null;

    for (let i = index + 1; i < this.questionCount(); i++) {
      let question = this.getQuestion(i);
      if (!question) {
        break;
      }

      if (answered.indexOf(question.question_nid) === -1) {
        nextUnanswered = i;
        break;
      }
    }

    if (nextUnanswered === null) {
      // No unanswered question beyond the current question. Look back to the first, and search previous questions.
      for (let i = 0; i < index; i++) {
        let question = this.getQuestion(i);
        if (!question) {
          break;
        }

        if (answered.indexOf(question.question_nid) === -1) {
          nextUnanswered = i;
          break;
        }
      }
    }

    return nextUnanswered;
  }

  /**
   * Prepares the graded results for display. Returns an array of questions,
   * where each question is marked with 'correct_answered' based on the
   * session's answers.
   *
   * @param session
   */
  prepareExamResults(session) {
    let results = [];

    if (this.questions) {
      this.questions.forEach(question => {
        // Create a clone so that the exam object is not modified.
        let question_result = Object.assign({}, question);

        // If the session has the 'correct' flag, copy that to the question data.
        if (session.answers) {
          session.answers.forEach(answer => {
            if (answer.question_nid == question_result.question_nid) {
              question_result['submitted_answer'] = answer.answer;
              question_result['correct_answered'] = answer.correct ? 1 : 0;
            }
          });
        }

        results.push(question_result);
      });
    }

    return results;
  }

  /**
   * Updates the given questions within this object.
   *
   * @param data
   *   A question array.
   */
  mergeData(data) {
    if (!data.question_nid) {
      return;
    }

    this.questions.forEach(question => {
      if (question.question_nid == data.question_nid) {
        Object.assign(question, data)
      }
    });
  }

}
