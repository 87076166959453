import {Component, Input, OnInit} from '@angular/core';
import {LocalStorageService} from '../../services/local-storage.service';

@Component({
  selector: 'app-session-dropdown',
  templateUrl: './session-dropdown.component.html',
  styleUrls: ['./session-dropdown.component.scss']
})
export class SessionDropdownComponent implements OnInit {
  @Input() sessions; // Should be the data from userinfo.accessible_sessions.
  @Input() selectedSession;

  constructor(private storageService: LocalStorageService) { }

  ngOnInit() {
  }

  /**
   * Click handler for the dropdown options.
   */
  selectSession(session) {
    // Future sessions cannot be selected, unless they are made current (selected in field_user_session)
    if (session.future && !session.current) {
      return;
    }

    // Note, parent element needs to react and set this.selectedSession if the change actually occurs.
    this.storageService.setStoredValue('selected-bar-session', session.nid);
  }

  /**
   * Finds the title for the session nid in this.selectedSession.
   */
  getSelectedSessionTitle() {
    let title = 'Select Session...';
    if (!this.sessions) {
      return title;
    }

    this.sessions.forEach((session) => {
      if (this.selectedSession == session.nid) {
        title = session.title;
      }
    });

    return title;
  }

}
