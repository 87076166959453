import {Injectable} from '@angular/core';
import {JoyrideService} from 'ngx-joyride';
import {Router} from '@angular/router';
import {UtilityService} from './utility.service';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TourServiceService {
  get steps() {
    return this.generateStepsCopy(this.getSubscriptionName());
  }

  userInfo = null;
  modalButtons = [
    {
      text: 'No, thanks',
      type: 'primary',
      cancel: true,
      callback: null
    },
    {
      text: 'Okay, let\'s go!',
      type: 'primary',
      cancel: false,
      callback: () => this.startTour()
    }
  ];

  constructor(private  joyrideService: JoyrideService,
              private utilityService: UtilityService,
              private apiService: ApiService) {}

  getSubscriptionName(): 'One-Timers' | 'Best Multis' {
    const { student_type } = this.userInfo;
    return student_type === 'one_timers' ? 'One-Timers' : 'Best Multis';
  }
  /**
   * Opens tour modal
   */
  openTourModal() {
    const {first_name, student_type } = this.userInfo;
    this.utilityService.openModal(
      `Welcome to ${this.getSubscriptionName()}`,
      `Hi ${first_name} and welcome! Would you like to take a quick tour of all the features of this student portal?`,
      () => this.onStopTour(),
      this.modalButtons,
      'tour-start-modal');
  }

  /**
   * If modal is dismissed, or tour starts, set config to disable modal
   * from opening on subsequent visits
   */
  onStopTour() {
    return this.apiService.setStudentValue('disable_tour', true);
  }

  /**
   * starts tour
   */
  startTour() {
    this.joyrideService.startTour(
      {
        steps: this.addStepsBasedOnUserAccess(),
        showPrevButton: true,
        themeColor: '#3B86FF',
      }
    );
    this.scrollTo();
  }

  /**
   * Add the steps to the tour in specific sequence
   */
  addStepsBasedOnUserAccess(): string[] {
    const steps = [
      this.steps.stepProfile.id,
      this.steps.stepFeedback.id,
      this.steps.stepClassroom.id,
      this.steps.stepPractice.id,
      this.steps.stepOfficial.id,
      // TODO: skipping practice pages
      // this.steps.stepSeven.id,
      // this.steps.stepEight.id,
      // this.steps.stepNine.id,
      this.steps.stepResults.id,
    ];

    if (this.userInfo && this.userInfo.student_type === 'one_timers') {
      steps.push(this.steps.stepRemoteClasses.id);
      steps.push(this.steps.stepAssignments.id);
      steps.push(this.steps.stepMessages.id);
    }

    steps.push(this.steps.stepSupport.id);
    steps.push(this.steps.stepSearch.id);

    return steps;
  }


  scrollTo(x = 0, y = 0) {
    setTimeout(() => window.scrollTo(x, y), 5);
  }

  generateStepsCopy(subscriptionName) {
    const { student_type } = this.userInfo;

    return {
      // stepOne is the modal and handled in openTourModal
      stepProfile: {
        step: 'stepProfile',
        id: 'stepProfile@home',
        text: '<h3>Profile & Account Info</h3>Anything and everything administrative. Make a payment, edit your profile/contact/billing information or change your password.'
      },
      stepFeedback: {
        step: 'stepFeedback',
        id: 'stepFeedback@home',
        text: `<h3>Gain Real Time Feedback</h3>${subscriptionName}' Advanced Analytics Dashboard provides detailed insights into your performance and progress in every subject and subsection. See how you’re comparing to your baseline as well as other students in your bar exam session. Want to hide the dashboard for now? Click the hide button up top.`
      },
      stepClassroom: {
        step: 'stepClassroom',
        id: 'stepClassroom@subjects',
        text: student_type === 'one_timers' ?
          `<h3>Study Anywhere & Anytime</h3>Access ${subscriptionName}' Subject Outline Books, Rules for Essays, Model Answers and Audio/Video Reviews across all your devices on our Online Learning Platform.` :
          `<h3>Study Anywhere & Anytime</h3>Access ${subscriptionName}' MBE Subject Outline Books, Critical Breakdowns™ and every Subtopic Audio/Video Reviews across all your devices on our Online Learning Platform. `
      },
      stepPractice: {
        step: 'stepPractice',
        id: 'stepPractice@practice',
        text: '<h3>Efficiently Practice MBEs</h3>Build custom MBE quizzes by subject and/or subtopic. Personalized settings enable you to tailor the practice to your strengths, weaknesses and preferences to maximize learning and study time.'
      },
      stepOfficial: {
        step: 'stepOfficial',
        id: 'stepOfficial@official',
        text: '<h3>Take Official Practice Exams</h3>Take the NCBE’s Official Practice Exams (OPE 1-4) under simulated test conditions.'
      },

      // EXAM TOUR STEPS MAY NEED DIFFERENT TOUR
      // stepSeven: {
      //   step: 'stepSeven',
      //   id: 'stepSeven@practice/exam/1695',
      //   text: '<h3>Navigate questions</h3>Click on a question number to go to it. Blue means you’ve already answered it. A red outline means you flagged it.'
      // },
      // stepEight: {
      //   step: 'stepEight',
      //   id: 'stepEight',
      //   text: '<h3>Select, star, and hide choices</h3>Select your answer by clicking on it then click Answer below. Click on the eye or star icons to hide or star a choice to help narrow your options.'
      // },
      // stepNine: {
      //   step: 'stepNine',
      //   id: 'stepNine@practice/exam/1695',
      //   text: '<h3>Highlight text</h3>Text highlighting is available throughout the student portal. To highlight text, click the toggle, then double-click and drag your mouse over the text you want to highlight. Add an optional comment if you wish. To unhighlight text, hover over it click the “X” in the comment box.'
      // },
      stepResults: {
        step: 'stepResults',
        id: 'stepResults@results',
        text: '<h3>View Results, Timing & Unfinished Sessions</h3>An archive of all your MBE Quiz and MBE OPE Test results with timing for progress tracking. Additionally, links to any unfinished sessions and flagged questions – just click the “Continue” button to start where you left off.'
      },
      stepAssignments: {
        step: 'stepAssignments',
        id: 'stepAssignments@assignments',
        text: '<h3>Submit for Grading</h3>Download your Essay or PT assignment, upload the completed assignment and then download the graded assignment when notified to see your score & feedback.'
      },
      stepMessages: {
        step: 'stepMessages',
        id: 'stepMessages@messages',
        text: '<h3>Read Daily Messages</h3>Task-by-task daily schedule, messages from your instructor and class updates appear here.'
      },
      stepRemoteClasses: {
        step: 'stepRemoteClasses',
        id: 'stepRemoteClasses@live-classes',
        text: '<h3>Attend Class Remotely</h3>Can’t make it in person? Watch and interact in-real-time with the class remotely. All in-person classes for your session are live streamed and available on-demand.'
      },
      stepSupport: {
        step: 'stepSupport',
        id: 'stepSupport@home',
        text: '<h3>Utilize the Online Learning Platform</h3>All the basics covered in this tutorial, every software feature, tech support and much more is covered here.'
      },
      stepSearch: {
        step: 'stepSearch',
        id: 'stepSearch@home',
        text: '<h3>Quickly Find What You Need</h3>Utilize the search bar to quickly find all the content pertaining to a subject.<br/><br/>That’s the 30,000 foot Tour! Now, lets finish what you started.'
      },
    };
  }

}
