import {ExamBase} from './exam-base';

export class PracticeExam extends ExamBase {
  eid = null;
  exclude_official = null;
  show_answers = null;
  show_timer = null;
  use_timer = null;
  timestamp = null;
  uid = null;

  constructor(data) {
    super();
    this.copyData(data);
  }

  getId() {
    return this.eid;
  }
}
