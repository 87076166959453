import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ios-toggle',
  templateUrl: './ios-toggle.component.html',
  styleUrls: ['./ios-toggle.component.scss']
})
export class IosToggleComponent implements OnInit {
  @Input() state = false;
  @Output() changed = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onChange() {
    this.state = !this.state;
    this.changed.emit(this.state);
  }

}
