import {Component, Input, OnInit} from '@angular/core';
import {UtilityService} from '../../services/utility.service';

@Component({
  selector: 'app-exam-stats',
  templateUrl: './exam-stats.component.html',
  styleUrls: ['./exam-stats.component.scss']
})
export class ExamStatsComponent implements OnInit {
  @Input() session;
  @Input() exam;

  constructor(public utilityService: UtilityService) { }

  ngOnInit() {
  }

}
