import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit {
  @Input() numPages;
  @Input() activePage;
  @Output() jumpToPage = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  /**
   * Click handler for the page numbers.
   */
  pageClicked(page) {
    if (page >= 0 && page < this.numPages && page != this.activePage) {
      this.jumpToPage.emit(page);
    }
  }

}
