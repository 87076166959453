import {ExamBase} from './exam-base';

export class OfficialExam extends ExamBase {
  title = null;
  nid = null;

  constructor(data) {
    super();
    this.copyData(data);
  }

  getId() {
    return this.nid;
  }
}
