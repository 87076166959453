import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-essay-chart',
  templateUrl: './essay-chart.component.html',
  styleUrls: ['./essay-chart.component.scss']
})
export class EssayChartComponent implements OnInit {
  subjects;
  barExams = [];

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.apiService.getSubjects(false, false, true).subscribe(result => {
      if (result) {
        this.processSubjects(result);
      }
    });
  }

  /**
   * Prepares subject data for display.
   */
  processSubjects(subjects) {
    let seenBarExamNids = [];
    this.barExams = [];

    this.subjects = [];

    subjects.forEach((subject) => {
      subject.essaysByBarExam = {};
      subject.has_video = false;
      subject.has_audio = false;

      // Group essays by bar exam.
      if (subject.essays) {
        subject.essays.forEach((essay) => {

          // Collect all the bar exams that appear in any essays for display in the header.
          if (essay.bar_exam_nid && seenBarExamNids.indexOf(essay.bar_exam_nid) === -1) {
            seenBarExamNids.push(essay.bar_exam_nid);
            this.barExams.push({
              nid: essay.bar_exam_nid,
              title: essay.bar_exam_title,
              date: essay.bar_exam_date,
            });
          }

          if (typeof subject.essaysByBarExam[essay.bar_exam_nid] === 'undefined') {
            subject.essaysByBarExam[essay.bar_exam_nid] = [];
          }

          // The subject has a video/audio if any of the child sections have one.
          if (essay.has_video) {
            subject.has_video = true;
          }
          if (essay.has_audio) {
            subject.has_audio = true;
          }

          subject.essaysByBarExam[essay.bar_exam_nid].push(essay);
        });
      }

      this.subjects.push(subject);
    });

    // Order bar exams by exam date.
    this.barExams.sort((a, b) => {
      return a.date.localeCompare(b.date);
    });

    // Order subjects by title.
    this.subjects.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
  }

}
