import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sectionTitle'
})
export class SectionTitlePipe implements PipeTransform {

  /**
   * Transforms the section title to title case such that it keeps the section number at the beginning of
   * the title as is (because that has capital roman letters).
   */
  transform(value): string {
    let pattern = /^([IVX]+\.( ?[A-Z]\.( ?\d{1,2}\.?( ?[a-z]\.?)?)?)?)( .+)/gi;
    let transformed;
    let match;

    if (match = pattern.exec(value)) {
      transformed = match[1] + this.titleCase(match[5]);
    }
    else {
      transformed = this.titleCase(value);
    }

    return transformed;
  }

  /**
   * Converts the string to title case (all words' first letters are capitalized)
   */
  titleCase(str) {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }

}
