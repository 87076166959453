import {Component, Input, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  // If true, the loader will be displayed as inline-block.
  @Input() inline = false;
  // Observable that this loader corresponds to. The loader will be hidden when this observable is resolved or rejected.
  // Note: this needs to be already set when this component is loaded (e.g. in the init of the parent)
  @Input() observe: Observable<any>;

  visible = false;

  constructor() { }

  ngOnInit() {
    if (this.observe) {
      this.visible = true;
      this.observe
      .pipe(delay(500)) // avoid quick flash of spinner by delaying when the spinner starts
      .subscribe(
        result => { this.visible = false; },
        error => { this.visible = false; }
      );
    } else {
      // avoid quick flash of spinner by delaying when the spinner starts
      of({})
      .pipe(delay(500))
      .subscribe(() => {
        this.visible = true;
      })
    }
  }

}
