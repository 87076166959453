import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import * as Plyr from 'plyr';
import { EVENT_NAMES, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import {MessagingService} from '../../services/messaging.service';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {
  player = null;
  isOpen = false;
  currentPlaylist = null;
  currentTrack = null;
  nextTrack = null;
  previousTrack = null;
  isMobileDisplayShown = true;
  hoverPanelOpen = '';
  volume = 1;
  mutedVolume = 1;

  @ViewChild('volumeControl', {static: true}) volumeControl: ElementRef;

  @Output() isOpenEvent = new EventEmitter<boolean>();

  // Plyr lib control markup cannot be modified by Angular, so passing as string here.
  // Markup is taken from:
  // https://github.com/sampotts/plyr/blob/master/controls.md
  // Note, Plyr adds the click handlers, so the classes needs to match.
  controls = `
<div class="plyr__controls">
    <button type="button" class="plyr__control" id="show-track-list">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 394.667 394.667">
            <path d="M32,37.333c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S49.707,37.333,32,37.333z"/>
            <path d="M32,165.333c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S49.707,165.333,32,165.333z"/>
            <path d="M32,293.333c-17.813,0-32,14.4-32,32c0,17.6,14.4,32,32,32c17.6,0,32-14.4,32-32C64,307.733,49.813,293.333,32,293.333z"/>
            <rect x="96" y="304" width="298.667" height="42.667"/>
            <rect x="96" y="48" width="298.667" height="42.667"/>
            <rect x="96" y="176" width="298.667" height="42.667"/>
          </svg>
        </span>
        <span class="plyr__tooltip" role="tooltip">Track list</span>
    </button>

    <button type="button" class="plyr__control" data-plyr="back" id="previous-track">
        <span><svg xmlns="http://www.w3.org/2000/svg" width="9.726" height="10.653" viewBox="0 0 9.726 10.653"><rect width="2.146" height="10.653"/><path d="M21.371,6.88,12.97,12l8.4,5.122Z" transform="translate(-11.645 -6.676)"/></svg></span>
        <span class="plyr__tooltip" role="tooltip">Previous track</span>
    </button>
    <button type="button" class="plyr__control" data-plyr="rewind">
        <span><svg xmlns="http://www.w3.org/2000/svg" width="17.961" height="18.148" viewBox="0 0 17.961 18.148"><path d="M4358.886,3687.589a9.03,9.03,0,0,1-10.284-7.57.386.386,0,1,1,.763-.115,8.258,8.258,0,1,0,8.167-9.5,8.41,8.41,0,0,0-4.405,1.28l-.068.041,1.483,1.569a.386.386,0,0,1-.231.695h-3.471c-.213,0-.386-.377-.386-.59v-3.471a.386.386,0,0,1,.714-.2l1.354,1.428a9.027,9.027,0,1,1,6.364,16.434Z" transform="translate(-4348.598 -3669.545)"/><g transform="translate(4.657 5.992)"><path d="M4358.676,3678.86a1.456,1.456,0,0,1-.348,1,1.757,1.757,0,0,1-.98.535v.034a1.9,1.9,0,0,1,1.135.47,1.347,1.347,0,0,1,.379,1,1.661,1.661,0,0,1-.642,1.4,2.938,2.938,0,0,1-1.821.491,4.1,4.1,0,0,1-1.763-.339v-.9a4.041,4.041,0,0,0,.846.3,3.683,3.683,0,0,0,.86.107,1.81,1.81,0,0,0,1.092-.271.979.979,0,0,0,.36-.839.8.8,0,0,0-.4-.74,2.564,2.564,0,0,0-1.256-.235h-.545v-.821h.555q1.5,0,1.5-1.041a.765.765,0,0,0-.263-.623,1.181,1.181,0,0,0-.773-.219,2.343,2.343,0,0,0-.687.1,3.275,3.275,0,0,0-.784.394l-.494-.706a3.3,3.3,0,0,1,2.008-.636,2.382,2.382,0,0,1,1.483.409A1.338,1.338,0,0,1,4358.676,3678.86Z" transform="translate(-4354.636 -3677.317)"/><path d="M4365.43,3680.555a4.474,4.474,0,0,1-.526,2.433,1.99,1.99,0,0,1-3.2-.026,4.4,4.4,0,0,1-.542-2.407,4.5,4.5,0,0,1,.527-2.453,1.99,1.99,0,0,1,3.2.037A4.424,4.424,0,0,1,4365.43,3680.555Zm-3.246,0a4.585,4.585,0,0,0,.265,1.835.924.924,0,0,0,1.693-.008,6.322,6.322,0,0,0,0-3.656.92.92,0,0,0-1.693-.011A4.619,4.619,0,0,0,4362.184,3680.555Z" transform="translate(-4356.128 -3677.314)"/></g></svg></span>
        <span class="plyr__tooltip" role="tooltip">Rewind {seektime} secs</span>
    </button>
    <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
        <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-pause"></use></svg>
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-play"></use></svg>
        <span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>
    </button>
    <button type="button" class="plyr__control" data-plyr="fast-forward">
        <span><svg xmlns="http://www.w3.org/2000/svg" width="17.961" height="18.148" viewBox="0 0 17.961 18.148"><g transform="translate(0)"><path d="M4387.109,3678.663a9.025,9.025,0,0,1,14.038-7.507l1.354-1.428a.386.386,0,0,1,.532-.124.381.381,0,0,1,.182.328v3.471c0,.213-.172.59-.385.59h-3.471a.386.386,0,0,1-.231-.695l1.483-1.569-.068-.041a8.408,8.408,0,0,0-4.4-1.28,8.258,8.258,0,1,0,8.167,9.5.386.386,0,1,1,.763.115,9.03,9.03,0,0,1-17.957-1.356Z" transform="translate(-4387.109 -3669.545)"/><g transform="translate(4.657 5.992)"><path d="M4397.188,3678.86a1.459,1.459,0,0,1-.349,1,1.756,1.756,0,0,1-.979.535v.034a1.9,1.9,0,0,1,1.134.47,1.345,1.345,0,0,1,.379,1,1.661,1.661,0,0,1-.641,1.4,2.942,2.942,0,0,1-1.822.491,4.1,4.1,0,0,1-1.764-.339v-.9a4.043,4.043,0,0,0,.847.3,3.665,3.665,0,0,0,.859.107,1.809,1.809,0,0,0,1.092-.271.977.977,0,0,0,.361-.839.8.8,0,0,0-.4-.74,2.56,2.56,0,0,0-1.254-.235h-.546v-.821h.555q1.5,0,1.5-1.041a.772.772,0,0,0-.261-.623,1.185,1.185,0,0,0-.774-.219,2.352,2.352,0,0,0-.687.1,3.271,3.271,0,0,0-.783.394l-.493-.706a3.3,3.3,0,0,1,2.007-.636,2.381,2.381,0,0,1,1.483.409A1.34,1.34,0,0,1,4397.188,3678.86Z" transform="translate(-4393.147 -3677.317)"/><path d="M4403.941,3680.555a4.469,4.469,0,0,1-.526,2.433,1.989,1.989,0,0,1-3.2-.026,4.4,4.4,0,0,1-.541-2.407,4.5,4.5,0,0,1,.526-2.453,1.99,1.99,0,0,1,3.2.037A4.42,4.42,0,0,1,4403.941,3680.555Zm-3.246,0a4.568,4.568,0,0,0,.265,1.835.88.88,0,0,0,.845.55.89.89,0,0,0,.849-.558,6.333,6.333,0,0,0,0-3.656.921.921,0,0,0-1.694-.011A4.6,4.6,0,0,0,4400.7,3680.555Z" transform="translate(-4394.639 -3677.314)"/></g></g></svg></span>
        <span class="plyr__tooltip" role="tooltip">Forward {seektime} secs</span>
    </button>
    <button type="button" class="plyr__control" data-plyr="next-track" id="next-track">
        <span><svg xmlns="http://www.w3.org/2000/svg" width="9.726" height="12.341" viewBox="0 0 9.726 12.341"><g transform="translate(9.726 12.341) rotate(180)"><rect width="2.146" height="12.341"/><path d="M8.4,0,0,5.967l8.4,5.967Z" transform="translate(1.325 0.204)"/></g></svg></span>
        <span class="plyr__tooltip" role="tooltip">Next track</span>
    </button>

    <span class="break-on-mobile"></span>

    <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>

    <div class="plyr__progress">
        <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
        <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
        <span role="tooltip" class="plyr__tooltip">00:00</span>
    </div>

    <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>

    <div id="plyr-speed-select"><span id="plyr-speed-display">0.5×</span>
      <div id="plyr-speed-controls">
        <button type="button" class="plyr__control small" id="speed-0-5"><span>0.5×</span></button>
        <button type="button" class="plyr__control small" id="speed-1"><span>1×</span></button>
        <button type="button" class="plyr__control small" id="speed-2"><span>2×</span></button>
      </div>
    </div>

    <button type="button" class="plyr__control" aria-label="Volume" id="show-volume-panel">
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-volume"></use></svg>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Volume</span>
    </button>
</div>
`;

  constructor(
    private elementRef: ElementRef,
    private messagingService: MessagingService,
    private analyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {

    // Create plyr instance. Can only be done once on init due to file access restrictions.
    this.player = new Plyr('#player', {
      // debug: true,
      'controls': this.controls,
      'seekTime': 30
    });

    // Go to next track when current track is over.
    this.player.on('ended', event => {
      this.goToDirectionalTrack('next');
    });

    // Subscribe to when play button is clicked, and set player data.
    // Other components can start the audio by sending a start-audio message. The message data should contain .playlist (see createPlaylist() for
    // details), and optionally .audio_id to set which audio file the playing should begin with. The .audio_id value should match either
    // one of the .nid values in .playlist, if the playlist items are nodes (sections or essays), or the ordinal number of the audio
    // (e.g 1 to play the first one)
    this.messagingService.addObserver((message) => {
      if (message.messageType == 'start-audio') {
        let params = message.data;
        let firstTrack;

        this.isOpen = true;
        this.isOpenEvent.emit(true);
        this.currentPlaylist = this.createPlaylist(params.playlist);

        if (params.audio_id) {
          // Note, if playlist items have .nid properties, audio_id must be a nid, otherwise it must be the 1-based ordinal number of the track to play.
          firstTrack = this.getTrackDetails(params.audio_id);
        }
        else {
          // Start on the first one.
          firstTrack = this.currentPlaylist[0];
        }

        this.startTrack(firstTrack);
      }
    }, true);
  }

  /**
   * Set given track as the audio source and play. Re-attach click handers for plyr audio player.
   */
  startTrack(track) {
    this.hoverPanelOpen = '';

    if (!track || !track.audio) {
      return;
    }

    this.currentTrack = track;
    this.previousTrack = this.getDirectionalTrackDetails(this.currentTrack.audio_id, 'previous');
    this.nextTrack = this.getDirectionalTrackDetails(this.currentTrack.audio_id, 'next');

    this.player.source = {
      type: 'audio',
      sources: [
        {
          src: track.audio,
          type: 'audio/mp3',
        },
      ],
    };
    this.player.play();
    this.setSpeed(1);
    this.setupHandlers();

    // Apply the initial style for the volume bar.
    this.volumeChanged();

    this.analyticsService.sendEvent(
      EVENT_NAMES.MEDIA_PLAYED,
      `audio | ${track.title}`
    );
  }

  /**
   * Set previous or next track as the current track (if available), and play.
   */
  goToDirectionalTrack(direction) {
    let changeTrack = false;
    if (direction == 'previous' && this.previousTrack) {
      changeTrack = this.previousTrack;
    }
    else if (direction == 'next' && this.nextTrack) {
      changeTrack = this.nextTrack;
    }

    if (changeTrack) {
      this.startTrack(changeTrack);
    }
  }

  /**
   * Add click handlers for custom buttons: next and previous track buttons.
   * Plyr destroys custom event handlers on data change, so these must be recreated.
   */
  setupHandlers() {
    this.elementRef.nativeElement.querySelector('#previous-track').addEventListener('click', this.goToDirectionalTrack.bind(this, 'previous'));
    this.elementRef.nativeElement.querySelector('#next-track').addEventListener('click', this.goToDirectionalTrack.bind(this, 'next'));
    this.elementRef.nativeElement.querySelector('#plyr-speed-select').addEventListener('click', this.openSpeedControls.bind(this));
    this.elementRef.nativeElement.querySelector('#show-volume-panel').addEventListener('click', this.togglePanel.bind(this, 'volume'));

    if (this.currentPlaylist.length > 1) {
      this.elementRef.nativeElement.querySelector('#show-track-list').addEventListener('click', this.togglePanel.bind(this, 'track'));
    }
    else {
      // Hide tracklist button if there is only one track.
      this.elementRef.nativeElement.querySelector('#show-track-list').style.display = 'none';
    }

    let getSpeedCallback = (speed) => {
      return (e) => {
        // Needed because this also triggers click on #plyr-speed-select.
        e.stopPropagation();
        this.setSpeed(speed);
      }
    };
    this.elementRef.nativeElement.querySelector('#speed-0-5').addEventListener('click', getSpeedCallback(0.5) );
    this.elementRef.nativeElement.querySelector('#speed-1').addEventListener('click', getSpeedCallback(1));
    this.elementRef.nativeElement.querySelector('#speed-2').addEventListener('click', getSpeedCallback(2));
  }

  /**
   * Opens/closes a hover panel.
   */
  togglePanel(panel) {
    if (this.hoverPanelOpen == '') {
      // Nothing is open.
      this.hoverPanelOpen = panel;
    }
    else if (this.hoverPanelOpen == panel) {
      // Clicked the one that is open, close.
      this.hoverPanelOpen = '';
    }
    else {
      // Click one that is not open, switch to the clicked one.
      this.hoverPanelOpen = panel;
    }
  }

  /**
   * Opens the speed options.
   */
  openSpeedControls() {
    this.elementRef.nativeElement.querySelector('#plyr-speed-controls').style.display = 'block';
  }

  /**
   * Sets the playback speed
   */
  setSpeed(speed) {
    this.player.speed = speed;
    this.elementRef.nativeElement.querySelector('#plyr-speed-controls').removeAttribute('style');
    this.elementRef.nativeElement.querySelector('#plyr-speed-display').innerHTML = speed + 'x';
  }

  /**
   * Removes player data and hides player.
   */
  closeAudioPlayer(event) {
    this.player.stop();
    this.player.source = {
      type: 'audio',
      sources: [
        {
          src: '',
          type: 'audio/mp3',
        },
      ],
    };
    this.isOpen = false;
    this.isOpenEvent.emit(false);
  }

  /**
   * Get detailed track data for a given audio_id.
   */
  getTrackDetails(audio_id) {
    return this.currentPlaylist.find(function(track) {
      return track.audio && track.audio_id === audio_id
    });
  }

  /**
   * Get next or previous track in the playlist.
   */
  getDirectionalTrackDetails(audio_id, direction) {
    let playlist = this.currentPlaylist;

    for (let track = 0; track < playlist.length; track++) {
      if (playlist[track].audio && playlist[track].audio_id === audio_id) {
        switch (direction) {
          case 'next':
            if (playlist[track + 1]) {
              return playlist[track + 1];
            }
            break;
          case 'previous':
            if (playlist[track - 1]) {
              return playlist[track - 1];
            }
            break;
        }
      }
    }
  }

  /**
   * Creates a playlist by reading out the audio date from the provide list.
   *
   * @param list
   *   An array of playlist items. Each item may be a section/essay/audio chapter item. Each item should have .audio, containing the
   *   file url, .title, and optionally .nid. If .nid is given, that is used the identify the audio tracks. Otherwise (when the audio list
   *   is a chapter list within one node and they don't have unique nids), a sequential number is assigned to each audio file.
   */
  createPlaylist(list) {
    let playlist = [];
    let i = 1;

    for (let track = 0; track < list.length; track++) {
      if (list[track].audio) {
        playlist.push({
          audio: list[track].audio,
          title: list[track].title ? list[track].title : list[track].audio,
          audio_id: list[track].nid ? list[track].nid : (i++),
        });
      }
    }

    return playlist;
  }

  /**
   * Toggle mobile view of audio player
   */
  toggleMobileDisplay(){
    this.hoverPanelOpen = '';
    this.isMobileDisplayShown = !this.isMobileDisplayShown;
  }

  /**
   * Mute button click handler.
   */
  muteClicked() {
    if (this.volume == 0) {
      // Unmuting.
      this.volume = this.mutedVolume ? this.mutedVolume : 1;
    }
    else {
      // Muting. Store the current volume for unmuting.
      this.mutedVolume = this.volume;
      this.volume = 0;
    }

    this.volumeChanged();
  }

  /**
   * Called when volume is changed on the user interface.
   */
  volumeChanged() {
    this.player.volume = this.volume;

    // We are reusing plyr's styling, and due to the way it is styled, this attrib is needed for the blue bar to display.
    this.volumeControl.nativeElement.setAttribute('style', '--value: ' + (this.volume * 100) + '%');
  }
}
