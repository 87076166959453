import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {UtilityService} from '../../services/utility.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from "moment";

@Component({
  selector: 'app-official-results',
  templateUrl: './official-results.component.html',
  styleUrls: ['./official-results.component.scss']
})
export class OfficialResultsComponent implements OnInit, OnDestroy {
  currentDate;
  session_id;
  session;
  exam;
  results;
  paramObserver;
  sessionLoader;

  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.currentDate = moment().format('D MMM YYYY');
    // Read the route parameters. The exam is not shown until these are available.
    this.paramObserver = this.route.params.subscribe(params => {
      this.session_id = params['sid'];
      this.sessionLoader = this.apiService.getExamSession(this.session_id, true);

      this.sessionLoader.subscribe(
        result => {
          this.exam = result['exam'];
          this.session = result['session'];
        },
        error => {
          if (error.status == 403) {
            this.utilityService.openModal('Access Denied', 'You do not have access to this session.');
          }
          else {
            this.utilityService.openModal('Error', 'An error occurred while loading this page.');
          }
        });
    });
  }

  ngOnDestroy() {
    this.paramObserver.unsubscribe();
  }

}
