import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {debounceTime, filter, finalize, switchMap, tap} from "rxjs/operators";
import { EVENT_NAMES, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-classroom-notes',
  templateUrl: './classroom-notes.component.html',
  styleUrls: ['./classroom-notes.component.scss']
})
export class ClassroomNotesComponent implements OnInit, OnDestroy {

  noteForm: FormGroup;

  noteSaving = false;

  notesOpen = false;

  formChangeSubscriber;

  noteNid;

  @Input()
  activeItem;

  @Input()
  activeStudentData;

  constructor(
    private apiService: ApiService,
    private analyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    let note = '';
    let nid = this.activeItem.nid;
    if (this.activeStudentData && this.activeStudentData.notes) {
      note = this.decodeHtml(this.activeStudentData.notes);
      nid = this.decodeHtml(this.activeStudentData.nid);
    }
    this.noteForm = new FormGroup({
      'note': new FormControl(note),
      'nid': new FormControl(nid),
    });

    this.formChangeSubscriber = this.noteForm.get('note').valueChanges.pipe(
        filter(value => (!this.activeStudentData && value != '') || (this.activeStudentData && this.decodeHtml(this.activeStudentData.notes) != value)),
        debounceTime(1000),
        tap(() => this.setNid(this.noteForm.get('nid').value)),
        tap((value) => { this.activeStudentData.notes = value }),
        // tap(() => this.noteSaving = true),
        switchMap(value => this.apiService.patchNote(this.noteNid, value)
            .pipe(
                finalize(() => {
                  // this.noteSaving = false;
                }),
            )
        )
    ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if new section set default value of note.
    if (this.noteForm) {
      if (changes.activeStudentData || changes.activeItem) {
        let nid = this.activeItem.nid;
        let note = '';

        if (changes.activeStudentData) {
          if (changes.activeStudentData.currentValue && changes.activeStudentData.currentValue.notes) {
            note = this.decodeHtml(changes.activeStudentData.currentValue.notes);
            nid = changes.activeStudentData.currentValue.nid;
          }
        }

        this.noteForm.setValue({
          'note': note,
          'nid': nid,
        });
      }
    }
  }

  ngOnDestroy() {
    this.formChangeSubscriber.unsubscribe();
  }

  /**
   * Decodes html entities encoded by PHP.
   */
  decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  /**
   * Set NID to send in API (as active section could change by the time it's sent).
   */
  setNid(nid) {
    this.noteNid = nid;
  }

  toggleNotesDrawer() {
    this.notesOpen = !this.notesOpen;

    if (!!this.notesOpen) {
      this.analyticsService.sendEvent(
        EVENT_NAMES.TAKE_NOTES_DRAWER_OPENED
      );
    }
  }

}
