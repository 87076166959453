import {Component, Inject, Input, OnInit} from '@angular/core';
import {ExamSession} from '../../model/exam-session';
import {UtilityService} from '../../services/utility.service';
import {ExamBase} from '../../model/exam-base';
import { DOCUMENT } from '@angular/common';
import {MessagingService} from '../../services/messaging.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {AnnotatorService} from '../../services/annotator.service';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-exam-results',
  templateUrl: './exam-results.component.html',
  styleUrls: ['./exam-results.component.scss']
})
export class ExamResultsComponent implements OnInit {
  @Input() session: ExamSession;
  @Input() exam: ExamBase;
  results;

  constructor(public utilityService: UtilityService,
              private storageService: LocalStorageService,
              private annotatorService: AnnotatorService,
              private apiService: ApiService,
              private messagingservice: MessagingService,
              @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    // Format the data for display (questions are stored in the .exam, but submissions are stored in .session, merge those)
    this.results = this.exam.prepareExamResults(this.session);

    // There might be many nids on this page. Preload annotations in bulk for performance.
    let questionNids = [];
    this.results.forEach((question) => {
      if (question.question_nid) {
        questionNids.push(question.question_nid);
      }
    });
    this.annotatorService.preload(questionNids);

    this.messagingservice.addObserver((message) => {
      if (message.messageType == 'exam-question-clicked') {
        this.scrollToQuestion(message.data.question.question_nid);
      }
    });
  }

  scrollToQuestion(question_nid) {
    this.document.querySelector('#exam-result-question-' + question_nid).scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  /**
   * Called when the flag is clicked.
   */
  toggleFlaggedQuestion(question_nid) {
    let questionFlagged = !this.session.isQuestionFlagged(question_nid);
    this.session.flagQuestion(question_nid, questionFlagged);

    this.apiService.flagQuestion(this.session.sid, question_nid, questionFlagged).subscribe(
      result => {
      },
      error => {
        this.utilityService.debugLog('Question flagging failed.');
      });
  }

}
