import { Directive, HostListener } from '@angular/core';
import { environment } from "../../environments/environment";

@Directive({
  selector: '[appRestrictedContent]'
})
export class RestrictedContentDirective {
  @HostListener('contextmenu', ['$event'])
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    if (environment.production) {
      e.preventDefault();
    }
  }
  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    if (environment.production) {
      e.preventDefault();
    }
  }
  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    if (environment.production) {
      e.preventDefault();
    }
  }

  onRightClick(event) {
    // Prevent right-click context menu.
    if (environment.production) {
      event.preventDefault();
    }
  }

  constructor() { }

}
