import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../services/api.service";
import {UserInfo} from '../../model/user-info';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  userInfo;
  resources = null;
  showEssayChart = false;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.apiService.getResources().subscribe(result => {
      if (result) {
        this.resources = result;
      }
    });

    this.apiService.getUserInfo().subscribe((userInfo: UserInfo) => {
      this.userInfo = userInfo;
      this.showEssayChart = userInfo.canAccessPage('/essay-chart');
    });
  }

}
