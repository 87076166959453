import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ApiService} from '../../services/api.service';
import {UtilityService} from '../../services/utility.service';
import {UserInfo} from "../../model/user-info";
import {TourServiceService} from '../../services/tour-service.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {MessagingService} from '../../services/messaging.service';
import { EVENT_NAMES, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  userInfo;
  dateRangeValue;
  selectedRange = 'week';
  currentDate;
  firstName;
  isHidden = false;
  chartsHidden = true;
  baseUrl;
  meColor = '#3B86FF'; // $primary-color
  othersColor = '#FF6E6E'; // $red-button
  barChartColorSet = [
    '#3B86FF', // $primary-color
    '#F0F2F8', // $light-outline
  ];
  lineChartColorSet = [];
  pieChartColorSet = [
    '#3B86FF', // $primary-color
  ];
  comparativeEnabled = true;
  rangeOptions = [
    {label: 'Week', value: 'week'},
    {label: 'Month', value: 'month'},
    {label: 'Quarter', value: 'quarter'},
    {label: 'Year', value: 'year'},
  ];
  sourceData;
  pieHidden = true;
  subjects = [];
  selectedSubjectFilter = 'all';
  selectedSession = null;
  assignmentAnswers = [];
  messageObserver;
  data;

  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService,
    private storageService: LocalStorageService,
    private messagingService: MessagingService,
    public tourService: TourServiceService,
    private router: Router,
    private analyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.resetData();
    this.selectedSession = this.storageService.getStoredValue('selected-bar-session');

    this.messageObserver = this.messagingService.addObserver((message) => {
      if (message.messageType == 'stored-value-changed' && message.data.key == 'selected-bar-session') {
        this.selectedSession = message.data.new_value;
        this.loadData();
      }
    });

    this.currentDate = moment().format('D MMM YYYY');
    this.baseUrl = this.utilityService.getApiUrl();

    this.apiService.getSubjects(false, true).subscribe(result => {
      result.forEach(subject => {
        // Note, non-active users will be denied access, so subjects will never load for them.
        this.subjects.push({nid: subject.nid, title: subject.title});
      });

      // User info loading triggers the chart loading (via selectRange()). This needs to happen
      // after subjects are loaded because otherwise the page is not visible, and the chart will not
      // be able to adjust its size.
      this.loadUserInfo();
    });

    this.apiService.getAssignmentAnswers().subscribe(result => {
      if (result) {
        // Only display graded answers.
        result = result.filter(item => (item.status === 'graded' && item.grade !== null));

        this.assignmentAnswers = result;
      }
    });

    this.analyticsService.sendCustomPageViewEvent(
      this.router.url,
      'Dashboard'
    )
  }

  loadUserInfo() {
    this.apiService.getUserInfo().subscribe((userInfo: UserInfo) => {
      this.userInfo = userInfo;
      this.isHidden = this.userInfo.dashboard_hidden;

      // If no bar session is selected, default to the one in the user profile.
      if (!this.selectedSession && this.userInfo.session_nid) {
        this.selectedSession = this.userInfo.session_nid;
      }

      if (userInfo.isLoggedIn()) {
        this.firstName = userInfo.first_name;
      }

      // Trigger stat loading.
      this.selectRange('month');
    });
  }

  resetData() {
    this.data = {
      avg_answered: 0,
      user_answered: 0,
      most_answered: 0,
      avg_answered_graph: null,
      user_answered_graph: null,
      most_answered_graph: null,
      performance_graph: null,
      x_axis_ticks: [],
      score_graph: null,
      essay_graph: null
    };
  }

  /**
   * Triggered when the date value is changed.
   */
  onInputChange(val) {
    // Range is selected manually, deselect presets.
    this.selectedRange = null;

    this.loadData();

    this.analyticsService.sendEvent(
      EVENT_NAMES.DASHBOARD_DATE_RANGE_CUSTOM_DATE_FRAME
    );
  }

  onSubjectChange(val) {
    this.selectRange = null;

    this.loadData();

    const subjectTitle = this.subjects.find((subject) => subject.nid === val).title;
    this.analyticsService.sendEvent(
      EVENT_NAMES.DASHBOARD_DATE_RANGE_SUBJECT_FILTER,
      subjectTitle
    );
  }

  /**
   * Triggered when a preset date range is selected.
   * @param value
   */
  onSelectRange(value) {
    this.selectRange(value);
    this.analyticsService.sendEvent(EVENT_NAMES.DASHBOARD_DATE_RANGE_TIME_PERIOD_UNTIS, value);
  }

  selectRange(value) {
    this.selectedRange = value;

    let start = new Date();
    var end = new Date();

    start.setHours(0, 0, 0);
    end.setHours(23, 59, 59);

    if (value == 'week') {
      // Today is already included, so only needs to step 6 days.
      start.setDate(start.getDate() - 6);
    }
    else if (value == 'month') {
      start.setDate(start.getDate() - 29);
    }
    else if (value == 'quarter') {
      start.setMonth(start.getMonth() - 3);
    }
    else if (value == 'year') {
      start.setMonth(start.getMonth() - 12);
    }

    this.dateRangeValue = [start, end];
    this.loadData();
  }

  /**
   * Loads the chart data.
   */
  loadData() {
    let start = this.dateRangeValue[0];
    let end = this.dateRangeValue[1];

    // Ensure full days are included at the range boundaries.
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 59);

    this.pieHidden = true;

    this.resetData();
    this.apiService.getExamStats(start.getTime() / 1000, end.getTime() / 1000, this.selectedSubjectFilter, this.selectedSession).subscribe(result => {
      this.sourceData = result;
      this.prepareCharts();

      // Pie chart sizing hack. The pie chart adjusts to the container, but the container's style.height.px attrib is only set after
      // the pie chart is rendered so the pie chart thinks the container height is 0, and squeezes the charts. We need to delay the rendering
      // of the pie until after the container height is applied.
      setTimeout (() => {
        this.pieHidden = false;
      }, 100);

    });
  }

  /**
   * Prepares data in the format usable by the chart components.
   */
  prepareCharts() {
    let result = this.sourceData;
    if (!result) {
      return;
    }

    this.data.avg_answered = result.data.avg_answered;
    this.data.most_answered = result.data.most_answered;
    this.data.user_answered = result.data.user_answered;

    // Fill in chart data values (charts also need the unanswered counts to fill in the bar full width).
    // Brand new object is needed to trigger change detection in the graph component.
    this.data.avg_answered_graph = [
      {
        "name": "",
        "series": [
          {
            "name": "Answered",
            "value": this.data.avg_answered
          },
          {
            "name": "Unanswered",
            "value": 100 - this.data.avg_answered
          }
        ],
      }
    ];

    this.data.most_answered_graph = [
      {
        "name": "",
        "series": [
          {
            "name": "Answered",
            "value": this.data.most_answered
          },
          {
            "name": "Unanswered",
            "value": 100 - this.data.most_answered
          }
        ],
      }
    ];

    this.data.user_answered_graph = [
      {
        "name": "",
        "series": [
          {
            "name": "Answered",
            "value": this.data.user_answered
          },
          {
            "name": "Unanswered",
            "value": 100 - this.data.user_answered
          }
        ],
      }
    ];

    this.data.performance_graph = [];
    this.data.score_graph = [];
    this.lineChartColorSet = [];

    // We want the 'me' chart to be on top of the other. The chart always draws the second dataset on top, so we
    // put the 'me' dataset second if the 'other' is visible, otherwise 'me' is first.
    let meKey = 0;
    let othersKey = 0;

    if (this.comparativeEnabled) {
      this.data.performance_graph.push({
        "name": "Others",
        "series": []
      });

      this.data.score_graph.push({
        "name": "Others",
        "series": []
      });

      meKey = 1;
      this.lineChartColorSet.push(this.othersColor);
    }

    this.data.performance_graph.push({
      "name": "Me",
      "series": []
    });

    this.data.score_graph.push({
      "name": "Me",
      "series": []
    });

    this.lineChartColorSet.push(this.meColor);

    this.data.x_axis_ticks = [];


    if (result.data.daily_stats) {
      let keys = Object.keys(result.data.daily_stats).sort();

      keys.forEach(date => {
        var formattedDate = moment(date).format('MMM D');
        this.data.x_axis_ticks.push(formattedDate);

        // Performance stats 'me' line.
        this.data.performance_graph[meKey]['series'].push({
          "name": formattedDate,
          "custom_data": result.data.daily_stats[date]['me'],
          "color": this.meColor,
          "value": result.data.daily_stats[date]['me']['cumulative_perc']
        });

        if (this.comparativeEnabled) {
          // Performance stats 'others' line.
          this.data.performance_graph[othersKey]['series'].push({
            "name": formattedDate,
            "custom_data": result.data.daily_stats[date]['others'],
            "color": this.othersColor,
            "value": result.data.daily_stats[date]['others']['cumulative_perc']
          });
        }

        // Daily score 'me' line.
        this.data.score_graph[meKey]['series'].push({
          "name": formattedDate,
          "custom_data": result.data.daily_stats[date]['me'],
          "color": this.meColor,
          "value": result.data.daily_stats[date]['me']['perc']
        });

        if (this.comparativeEnabled) {
          // Daily score 'others' line.
          this.data.score_graph[othersKey]['series'].push({
            "name": formattedDate,
            "custom_data": result.data.daily_stats[date]['others'],
            "color": this.othersColor,
            "value": result.data.daily_stats[date]['others']['perc']
          });
        }

      });

      if (this.data.x_axis_ticks.length > 10) {
        this.data.x_axis_ticks = this.reduceTicks(this.data.x_axis_ticks, 10);
      }
    }

    if (result.data.essay_progress) {
      this.data.essay_graph = [];

      result.data.essay_progress.forEach(subject => {
        this.data.essay_graph.push({
          'name': subject.title,
          'value': subject.completed_perc
        });
      });
    }

    // The chart has a bug with reinitializing colors, so need to force it to be removed and readded to start fresh.
    this.chartsHidden = true;
    setTimeout(() => { this.chartsHidden = false}, 100);

    this.utilityService.debugLog('Chart data');
    this.utilityService.debugLog(this.data);
  }

  /**
   * Reduces the amount ot axis ticks such that it takes out values evenly along the axis.
   */
  reduceTicks(ticks, max) {
    let modulus = Math.floor(ticks.length / max);
    let reduced = [];

    for (let i = 0; i < ticks.length; i++) {
      if (i % modulus === 0) {
        reduced.push(ticks[i]);
      }
    }

    return reduced;
  }

  /**
   * Hides/shows the dashboard.
   */
  toggleHide() {
    this.isHidden = !this.isHidden;

    this.userInfo.dashboard_hidden = this.isHidden;
    this.apiService.setStudentValue('dashboard_hidden', this.userInfo.dashboard_hidden);

    if (!this.isHidden) {
      this.prepareCharts();
    }
  }

  /**
   * Hides/shows the comparative charts.
   */
  toggleComparative() {
    this.comparativeEnabled = !this.comparativeEnabled;
    this.prepareCharts();
    this.analyticsService.sendEvent(
      EVENT_NAMES.DASHBOARD_DATE_RANGE_TOGGLE_COMPARATIVE_CHARTS,
      (this.comparativeEnabled) ? 'enabled' : 'disabled'
    );
  }

  /**
   * Callback to format the chart labels.
   */
  tickFormatter(x) {
    return x + '%';
  }

  ngOnDestroy() {
    if (this.messageObserver) {
      this.messageObserver.unsubscribe();
    }
  }
}
