import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from "ngx-bootstrap/collapse";
import { ModalModule } from 'ngx-bootstrap/modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PlyrModule } from 'ngx-plyr';

import { AnnotatorDirective } from './directives/annotator.directive';
import { RestrictedContentDirective } from './directives/restricted-content.directive';

import { ApiService } from './services/api.service';
import { UtilityService } from './services/utility.service';
import { MessagingService } from './services/messaging.service';
import { LocalStorageService } from './services/local-storage.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AnnotatorService } from './services/annotator.service';

import { SubjectOutlineComponent } from './components/subject-outline/subject-outline.component';
import { ExamQuestionComponent } from './components/exam-question/exam-question.component';
import { ExamQuestionOverviewComponent } from './components/exam-question-overview/exam-question-overview.component';
import { ExamSelectorComponent } from './components/exam-selector/exam-selector.component';
import { ClassroomSubjectDropdownComponent } from './components/classroom-subject-dropdown/classroom-subject-dropdown.component';
import { ClassroomTabsComponent } from './components/classroom-tabs/classroom-tabs.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ExamTimerComponent } from './components/exam-timer/exam-timer.component';
import { ClassroomDownloadsComponent } from './components/classroom-downloads/classroom-downloads.component';
import { ClassroomEssayNavigationComponent } from './components/classroom-essay-navigation/classroom-essay-navigation.component';
import { ClassroomOutlineNavigationComponent } from './components/classroom-outline-navigation/classroom-outline-navigation.component';
import { ClassroomEssaysComponent } from './components/classroom-essays/classroom-essays.component';
import { ClassroomOutlinesComponent } from './components/classroom-outlines/classroom-outlines.component';
import { IconToggleComponent } from './components/icon-toggle/icon-toggle.component';
import { ExamResultsComponent } from './components/exam-results/exam-results.component';
import { ExamStatsComponent } from './components/exam-stats/exam-stats.component';
import { ModalComponent } from './components/modal/modal.component';
import { HeaderComponent } from './components/header/header.component';
import { ChartBarComponent } from './components/chart-bar/chart-bar.component';
import { ChartLineComponent } from './components/chart-line/chart-line.component';
import { SubjectBarChartsComponent } from './components/subject-bar-charts/subject-bar-charts.component';
import { IosToggleComponent } from './components/ios-toggle/ios-toggle.component';
import { AnnotationEnableSwitchComponent } from './components/annotation-enable-switch/annotation-enable-switch.component';
import { PagerComponent } from './components/pager/pager.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { ClassroomNotesComponent } from './components/classroom-notes/classroom-notes.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { TourStepContentComponent } from './components/tour-step-content/tour-step-content.component';
import { NotifyBannerComponent } from './components/notify-banner/notify-banner.component';
import { SessionDropdownComponent } from './components/session-dropdown/session-dropdown.component';
import { FileAttachmentsComponent } from './components/file-attachments/file-attachments.component';

import { FullHtmlPipe } from './pipes/full-html.pipe';
import { SectionTitlePipe } from './pipes/section-title.pipe';

import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PracticeComponent } from './pages/practice/practice.component';
import { PracticeExamComponent } from './pages/practice-exam/practice-exam.component';
import { PracticeResultsComponent } from './pages/practice-results/practice-results.component';
import { ClassroomComponent } from './pages/classroom/classroom.component';
import { ClassroomSubjectComponent } from './pages/classroom-subject/classroom-subject.component';
import { OfficialComponent } from './pages/official/official.component';
import { OfficialExamComponent } from './pages/official-exam/official-exam.component';
import { OfficialResultsComponent } from './pages/official-results/official-results.component';
import { AssignmentsComponent } from './pages/assignments/assignments.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { LiveClassesComponent } from './pages/live-classes/live-classes.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { ResultsComponent } from './pages/results/results.component';
import { SearchComponent } from './pages/search/search.component';
import { EssayChartComponent } from './pages/essay-chart/essay-chart.component';
import {JoyrideModule} from 'ngx-joyride';
import { RouteTransformerDirective } from './directives/route-transformer.directive';
import { TimerStateService } from './services/timer-state.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';

@NgModule({
  declarations: [
    HomeComponent,
    PageNotFoundComponent,
    AppComponent,
    PracticeComponent,
    SubjectOutlineComponent,
    PracticeExamComponent,
    ExamQuestionComponent,
    ExamQuestionOverviewComponent,
    PracticeResultsComponent,
    ClassroomComponent,
    ClassroomSubjectDropdownComponent,
    ClassroomTabsComponent,
    ClassroomSubjectComponent,
    OfficialExamComponent,
    ExamSelectorComponent,
    LoaderComponent,
    OfficialComponent,
    OfficialResultsComponent,
    ExamTimerComponent,
    ClassroomDownloadsComponent,
    ClassroomEssayNavigationComponent,
    ClassroomOutlineNavigationComponent,
    ClassroomEssaysComponent,
    ClassroomOutlinesComponent,
    IconToggleComponent,
    ExamResultsComponent,
    ClassroomNotesComponent,
    AudioPlayerComponent,
    FullHtmlPipe,
    ExamStatsComponent,
    AssignmentsComponent,
    ModalComponent,
    HeaderComponent,
    MessagesComponent,
    LiveClassesComponent,
    ChartBarComponent,
    ChartLineComponent,
    SubjectBarChartsComponent,
    ResourcesComponent,
    RestrictedContentDirective,
    AnnotatorDirective,
    IosToggleComponent,
    AnnotationEnableSwitchComponent,
    ResultsComponent,
    PagerComponent,
    VideoPlayerComponent,
    SearchComponent,
    SectionTitlePipe,
    TourStepContentComponent,
    RouteTransformerDirective,
    EssayChartComponent,
    NotifyBannerComponent,
    SessionDropdownComponent,
    FileAttachmentsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    NgxChartsModule,
    PlyrModule,
    AngularSvgIconModule
  ],
  providers: [
    UtilityService,
    MessagingService,
    LocalStorageService,
    AuthGuardService,
    AnnotatorService,
    ApiService,
    TimerStateService,
    GoogleAnalyticsService
  ],
  entryComponents: [
    ModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
