import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.scss']
})
export class ChartBarComponent implements OnInit {
  @Input() colorSet = [
    '#3B86FF', // $primary-color
    '#FF6E6E', // $red-button
    '#F0F2F8', // $light-outline
    '#999999'
  ];
  @Input() data;
  @Input() tooltipShowLabel = true;
  @Input() tooltipSuffix = '';

  constructor() { }

  ngOnInit() {
  }

}
