import {BaseModel} from './base-model';

export class UserInfo extends BaseModel {
  uid = null;
  username = null;
  email = null;
  first_name = null;
  last_name = null;
  session_end_date = null;
  session_nid = null;
  session_name = null;
  session_start_date = null;
  student_status = null;
  student_type = null;
  unread_messages = 0;
  unread_assignments = 0;
  subscription_name = null;
  referral_code = null;
  referral_bonus = null;
  paid_student = null;
  trial_days_left = 0;
  dashboard_hidden = null;
  disable_tour = null;
  wrong_answer_count = null;
  accessible_sessions = null;
  in_assigned_session = null;
  subscribed_session = null;
  subscribed_session_start_date = null;
  subscribed_session_end_date = null;

  constructor(data) {
    super();
    this.copyData(data);
  }

  /**
   * Checks if the user is logged in.
   */
  isLoggedIn() {
    return !!this.uid;
  }

  /**
   * Checks if the user has a paid subscription.
   */
  hasSubscription() {
    return this.isLoggedIn() && this.student_status == 'active';
  }

  /**
   * Checks if the user is inactive (has no access).
   */
  isInactive() {
    return !this.isLoggedIn() || (this.student_status != 'active' && this.student_status != 'trial');
  }

  /**
   * Checks if the current user can access a given path, based on the student type/status.
   */
  canAccessPage(url) {
    // Restrict non-onetimers users from accessing the following pages.
    let onetimersOnlyPaths = [
      '/essay-chart',
      '/messages',
      '/live-classes',
      '/resources',
      '/assignments'
    ];

    // Restrict trial users for accessing the following pages.
    let activeOnlyPaths = [
      '/official'
    ];

    // These paths can be always accessed, even if the user is not an active student.
    let alwaysAllow = [
      '/',
      '/home',
      '/messages',
    ];

    if (!this.isLoggedIn() && ['/', '/home'].indexOf(url) == -1) {
      return false;
    }

    if (this.student_type !== 'one_timers' && onetimersOnlyPaths.indexOf(url) > -1) {
      return false;
    }

    if (this.student_status === 'trial' && activeOnlyPaths.indexOf(url) > -1) {
      return false;
    }

    if ((this.student_status !== 'active' && this.student_status !== 'trial') && alwaysAllow.indexOf(url) == -1) {
      return false;
    }

    return true;
  }

}
