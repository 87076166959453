import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {ReplaySubject} from 'rxjs';
import { EVENT_NAMES, GoogleAnalyticsService } from './google-analytics.service';

declare var jQuery: any;
declare var Annotator: any;

@Injectable({
  providedIn: 'root'
})
export class AnnotatorService {
  preloaded = {};
  preloadObservable;

  constructor(
    private apiService: ApiService,
    private analyticsService: GoogleAnalyticsService
  ) {
    let _this = this;

    // checkForEndSelection() is triggered when a selection is made, and it shows a floating "adder" button, which if clicked opens the
    // annotation editor. The annotation editor has a save button that triggers saving.
    // Here we bypass the "adder" button and immediately save the selection.
    let checkForEndSelectionOrig = Annotator.prototype.checkForEndSelection;
    Annotator.prototype.checkForEndSelection = function (event) {
      // The "adder" button's jQuery reference is stored in this.adder. Remove it, and replace with an empty jQuery object so there is
      // no error when the original function tries to make it visible.
      this.adder.remove();
      this.adder = jQuery();

      // The original function does some checks and returns undefined if the selection should be ignored.
      // If there is any selection, that is now stored in this.selectedRanges.
      let result = checkForEndSelectionOrig.apply(this, arguments);

      if (result && this.selectedRanges.length) {
        // Create a new empty annotation. Borrowed from Annotator.onAdderClick().
        let annotation = this.setupAnnotation(this.createAnnotation());
        // annotationCreated event will trigger saving in the store plugin.
        this.publish('annotationCreated', [annotation]);

        // Clear text selection.
        window.getSelection ? window.getSelection().removeAllRanges() : null;
      }
    };

    // Add custom functionality to Annotator.js' store plugin.
    // Note: We are overriding the prototype, so this needs to run only once.
    // Note: We need the function keyword here to capture the original this (the Annotator Store object)
    Annotator.Plugin.Store.prototype._apiRequest = function (action, obj, onSuccess) {
      _this.annotatorApiRequest(this, action, obj, onSuccess);
    };
  }

  /**
   * Replaces the _apiRequest method of the Annotator.js' Store plugin.
   * Original source code is here:
   * https://github.com/openannotation/annotator/blob/v1.2.x/src/plugin/store.coffee
   * (see also src/lib/annotator.1.2.10/source/src)
   *
   * @param store
   *   The annotator store instance (Annotator.Plugin.Store).
   * @param action
   *   Action being taken on the annotation: create, update, destroy, read, search
   * @param obj
   *   Annotation object. The attribs to store are: id, quote, ranges, text.
   * @param onSuccess
   *   Success callback. The first param should be the annotation's data.
   */
  annotatorApiRequest(store, action, obj, onSuccess) {
    const methodMap = {
      'read': 'get',
      'search': 'get',  // Not implemented
      'create': 'post',
      'update': 'patch',
      'destroy': 'delete'
    };

    // Nid is specified when the Annotator object is created (in AnnotatorDirective)
    const nid = store.options.annotationData.nid || null;
    const segment = store.options.annotationData.segment || null;

    const method: string = methodMap[action] || 'get';
    const path: string = '/api/annotations' + ((obj && obj.id) ? '/' + obj.id : '');

    const successCallback = (result) => {
      if (onSuccess) {
        onSuccess(result)
      }
    };

    const errorCallback = (error) => {
      console.log('Unable to ' + action + ' annotations.');
    };

    if (method === 'get') {
      let query = {};
      let multiple = false;

      // If not loading a specific annotation, add filter for the given content.
      if (!obj || !obj.id) {
        query['nid'] = nid;
        query['segment'] = segment;
        multiple = true;
      }

      // Fetches the annotations from either the preloaded data or from the api.
      const fetchData = () => {
        if (multiple && this.preloaded[query['nid']]) {
          // There may be nothing loaded for the given segment, but still skip the api request because everything has already been loaded for this nid.
          let annotations = this.preloaded[query['nid']][query['segment']] ? this.preloaded[query['nid']][query['segment']] : [];
          successCallback(annotations);
        }
        else {
          this.apiService.makeHttpRequest(path, 'get', null, null, query).subscribe(successCallback, errorCallback);
        }
      };

      if (this.isPreloading()) {
        this.afterPreload(fetchData);
      }
      else {
        fetchData();
      }
    }
    else {
      let annotation = {
        id: obj.id,
        nid: nid,
        segment: segment,
        quote: obj.quote,
        ranges: obj.ranges,
        text: obj.text
      };

      // Clear all preloaded items after any update.
      if (this.isPreloading()) {
        this.afterPreload(() => {
          this.preloaded = {};
        });
      }
      else {
        this.preloaded = {};
      }

      this.apiService.makeHttpUpdateRequest(path, {annotation: annotation}, method).subscribe(successCallback, errorCallback);

      this.analyticsService.sendEvent(
        EVENT_NAMES.TEXT_HIGHLIGHTED
      )
    }
  }

  /**
   * Preloads annotations for the given nids. Needed because if there are multiple annotations on the same page, the each directive
   * loads its data one by one.
   *
   * @param nids
   *   Array of nids.
   */
  preload(nids) {
    let query = {nid: nids.join(',')};
    this.preloadObservable = new ReplaySubject<boolean>(1);

    this.apiService.makeHttpRequest('/api/annotations', 'get', null, null, query).subscribe(
      (result) => {
        if (result && Array.isArray(result)) {

          result.forEach((a) => {
            if (!a.nid || !a.segment) {
              return;
            }

            if (!this.preloaded[a.nid]) {
              this.preloaded[a.nid] = {};
            }
            if (!this.preloaded[a.nid][a.segment]) {
              this.preloaded[a.nid][a.segment] = [];
            }

            this.preloaded[a.nid][a.segment].push(a);
          });

          // For the nids where nothing was returned, indicated that we have tried loading it to prevent futher api calls.
          nids.forEach((nid) => {
            if (!this.preloaded[nid]) {
              this.preloaded[nid] = {};
            }
          });

        }

        this.preloadObservable.next();
        this.preloadObservable.complete();
        this.preloadObservable = null;
      },
      (error) => {
        this.preloadObservable.next();
        this.preloadObservable.complete();
        this.preloadObservable = null;
      });

  }

  /**
   * Checks if preloading is in progress.
   */
  isPreloading() {
    return this.preloadObservable ? true : false;
  }

  /**
   * Registers a callback to be called after preloading is done.
   *
   * @param callback
   */
  afterPreload(callback) {
    if (!this.preloadObservable) {
      return;
    }

    this.preloadObservable.subscribe(
      (result) => {
        callback();
      },
      (error) => {
        callback();
      });
  }

}
