import {Inject, Injectable} from '@angular/core';
import { environment } from "../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ModalComponent} from '../components/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    @Inject(DOCUMENT) private document,
    private bsModalService: BsModalService,
    private route: ActivatedRoute
  ) { }

  /**
   * Returns the base url for the api backend.
   */
  getApiUrl() {
    let appHost = this.document.location.hostname;
    let apiUrl;

    if (environment.apiUrlMap && environment.apiUrlMap[appHost]) {
      apiUrl = environment.apiUrlMap[appHost];
    }
    else {
      apiUrl = environment.apiDefaultUrl;
    }

    return apiUrl;
  }

  /**
   * Reads a cookie.
   */
  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  deleteCookie(name) {
    this.setCookie(name, '', -1);
  }

  setCookie(name: string, value: string, expireDays: number = 365, path: string = '') {
    let d:Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires:string = `expires=${d.toUTCString()}`;
    let cpath:string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  /**
   * Logs a message to the console in dev mode,
   * and in production mode if debugOnProd is true
   * @param message
   */
  debugLog(message: any, debugOnProd: boolean = false) {
    if (!environment.production || debugOnProd) {
      console.log(message);
    }
  }

  /**
   * Converts numeric indexing to a letter.
   */
  indexLetter(index) {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return letters[index] ? letters[index] : '';
  }

  /**
   * Returns the current timestamp.
   */
  now() {
    return Math.round((new Date().getTime()) / 1000);
  }

  /**
   * Formats the time as min:sec, and displays it.
   *
   * @param time
   *   Time period to display, in seconds.
   */
  formatTime(time) {
    let prefix = time < 0 ? '-' : '';
    time = Math.abs(time);
    let seconds = Math.floor(time % 60);
    let minutes = Math.floor(time / 60);

    return prefix + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }

  /**
   * Evaluates a value to a boolean. 0, "0", null, false, and undefined qualifies as false;
   */
  toBool(value) {
    return !(value === '0' || value === 0 || value === null || value === false || value === undefined);
  }

  /**
   * Opens a modal message window.
   *
   * @param header
   *   Modal's header.
   * @param body
   *   Modal's body content.
   * @param closeCallback
   *   Callback to call when the modal is closed.
   * @param buttons
   *   Array of buttons to show in the footer. Defaults to one OK button.
   *   Each button object should have:
   *    text: Button text
   *    type: Button style, primary or secondary
   *    cancel: Should be true, if the button acts as a cancel button (i.e. does nothing but close the modal)
   *    callback: Callback to call when this button is clicked. Note: the closeCallback is called for all buttons regardless.
   *    className: option class name to apply to modal
   */
  openModal(header: string, body: string, closeCallback?, buttons?, className?: string) {
    const modalRef: BsModalRef = this.bsModalService.show(ModalComponent);

    buttons = buttons || [
      {text: 'OK', type: 'primary', cancel: true},
    ];

    buttons.forEach(button => {
      // Default to 'secondary' type.
      if (!button.type) {
        button.type = 'secondary';
      }

      // Add common functionality to all button clicks.
      const originalCallback = button.callback;
      button.callback = () => {
        modalRef.hide();
        closeCallback && closeCallback();
        originalCallback && originalCallback();
      };
    });


    // Note: This runs after the ngOnInit of ModalComponent, so the variables set there are overridden.
    modalRef.content.header = header;
    modalRef.content.body = body;
    modalRef.content.buttons = buttons;
    if (className) {
      modalRef.content.className = className;
    }
  }

  /**
   * Redirects to an external url.
   * @param url
   */
  externalRedirect(url) {
    document.location.href = url;
  }

  /**
   * Checks if the site is being loaded within the wrapper app.
   * (the iOS wrapper app has a custom user agent string)
   */
  isInApp() {
    let ua = window.navigator.userAgent;
    let match = /OneTimers\/iOS/;

    return match.test(ua);
  }

  getDefaultTimerLength(numberOfQuestions: number): number {
    return numberOfQuestions * 100; // Time allowance is 100 secs per question.
  }

}
