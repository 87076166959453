import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MessagingService} from '../../services/messaging.service';
import {LocalStorageService} from '../../services/local-storage.service';
import { EVENT_NAMES, GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-classroom-outlines',
  templateUrl: './classroom-outlines.component.html',
  styleUrls: ['./classroom-outlines.component.scss']
})
export class ClassroomOutlinesComponent implements OnInit {
  @Input()
  activeSection;

  @Input()
  activeSectionStudentData;

  @Input()
  studentData;

  @Input()
  flattenedSubject;

  @Output()
  nextSectionClickEmitted = new EventEmitter();

  @Output()
  previousSectionClickEmitted = new EventEmitter();

  @Output()
  studentDataChangedEmitted = new EventEmitter<object>();

  constructor(
    private messagingService: MessagingService,
    private storageService: LocalStorageService,
    private analyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
  }

  /**
   * Click handler for section Next button.
   */
  nextSectionClickedEmit() {
    this.nextSectionClickEmitted.emit();
  }

  /**
   * Click handler for section Previous button.
   */
  previousSectionClickedEmit() {
    this.previousSectionClickEmitted.emit();
  }

  /**
   * Click handler for section navigation checkbox.
   */
  studentDataChangedEmit(event, nid, complete) {
    let params = {
      nid: nid,
      complete: complete ? 1 : 0,
    }
    this.studentDataChangedEmitted.emit(params);
  }

  /**
   * Click handler for audio file play.
   */
  startAudio(section) {
    let params = {
      playlist: section.audio_chapters
    }

    this.messagingService.sendMessage('start-audio', params);
  }

  onVideoPlayedFirstTime() {
    this.analyticsService.sendEvent(
      EVENT_NAMES.MEDIA_PLAYED,
      `video | ${this.activeSection.title}`
    )
  }

}
