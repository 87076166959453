import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../services/api.service";
import {MessagingService} from "../../services/messaging.service";
import {UserInfo} from '../../model/user-info';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  userInfo;
  messages = null;
  totalPages;
  activePage = 0;
  selectedMessage = null;
  todaysDate = new Date().toDateString().substring(4);
  queryObservable;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private messagingService: MessagingService) { }

  ngOnInit() {
    this.queryObservable = this.route.queryParams.pipe(switchMap(query => {
      this.activePage = query['page'] ? parseInt(query['page']) : 0;
      this.messages = null;

      return this.apiService.getMessages(this.activePage);
    })).subscribe(result => {
      this.processMessages(result);
    });

    this.apiService.getUserInfo().subscribe((userInfo: UserInfo) => {
      this.userInfo = userInfo;
      this.refreshMessageData();
    });
  }

  /**
   * Select a message to view.
   */
  selectMessage(nid) {
    for (let item = 0; item < this.messages.length; item++) {
      if (this.messages[item].nid === nid) {
        this.selectedMessage = this.messages[item];
        window.scroll(0,0);
        this.markMessageAsRead(this.selectedMessage.nid, 1);
      }
    }
  }

  /**
   * Mark a single message as read or unread.
   */
  markMessageAsRead(nid, read) {
    // Check that there is an actual change in data before sending API call.
    let execute = true;
    for (let item = 0; item < this.messages.length; item++) {
      if (this.messages[item].nid === nid && this.messages[item].read == read) {
        execute = false;
      }
    }

    if (execute) {
      let params = [
        {
          message_nid: nid,
          message_read: read
        }
      ];
      this.apiService.markMessagesAsRead(params).subscribe(result => {
        if (result) {
          this.refreshMessageData();
        }
      });
    }
  }

  /**
   * Mark all unread messages as read.
   */
  markAllMessagesAsRead() {
    let unreadMessages = [];
    for (let item = 0; item < this.messages.length; item++) {
      if (this.messages[item].read == 0) {
        let singleMessage = {
          message_nid: this.messages[item].nid,
          message_read: 1
        };
        unreadMessages.push(singleMessage);
      }
    }
    if (unreadMessages.length > 0) {
      this.apiService.markMessagesAsRead(unreadMessages).subscribe(result => {
        if (result) {
          this.refreshMessageData();
        }
      });
    }
  }

  /**
   * Refresh the message data after change.
   */
  refreshMessageData() {
    this.apiService.getMessages(this.activePage).subscribe(result => {
      if (result) {
        this.processMessages(result);

        this.apiService.getUserInfo(true).subscribe((userInfo: UserInfo) => {
          this.userInfo = userInfo;
          this.messagingService.sendMessage('message-count-update', {number: userInfo.unread_messages});
        });
      }
    });
  }

  /**
   * Processes data from the api.
   */
  processMessages(result) {
    this.totalPages = result.total_pages ? parseInt(result.total_pages) : 1;
    if (this.activePage > this.totalPages - 1) {
      this.activePage = 0;
    }

    // Assume if there is no .messages, then the whole result is an array of messages (for backwards compatiblity)
    this.messages = result.messages ? result.messages : result;

    if (this.selectedMessage === null) {
      this.selectedMessage = this.messages[0];
    }
  }

  jumpToPage(page) {
    this.selectedMessage = null;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: page },
    });
  }

  ngOnDestroy() {
    this.queryObservable.unsubscribe();
  }
}
