import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {OfficialExam} from '../../model/official-exam';
import { ExamStateService } from 'src/app/services/exam-state.service';

@Component({
  selector: 'app-exam-selector',
  templateUrl: './exam-selector.component.html',
  styleUrls: ['./exam-selector.component.scss']
})
export class ExamSelectorComponent implements OnInit {
  exams: [OfficialExam] = null;
  @Output() start = new EventEmitter();
  selectedExam;

  constructor(
    private apiService: ApiService,
    private examsStateService: ExamStateService
  ) { }

  ngOnInit() {
    this.apiService.getExam('official').subscribe(result => {
      if (result) {
        this.exams = result;
        this.examsStateService.setExams(result);
      }
    });
  }

  onExamSelected(nid) {
    this.selectedExam = nid;
    this.examsStateService.setSelectedExam(nid);
  }

  startClicked() {
    this.start.emit(this.selectedExam);
  }

}
