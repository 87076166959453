import {Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {MessagingService} from '../../services/messaging.service';
import {ActivatedRoute, Router} from '@angular/router';
import {query} from '@angular/animations';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-classroom-essay-navigation',
  templateUrl: './classroom-essay-navigation.component.html',
  styleUrls: ['./classroom-essay-navigation.component.scss']
})
export class ClassroomEssayNavigationComponent implements OnInit {

  controls = {};

  @Input()
  subject;

  @Input()
  activeEssay;

  @Input()
  studentData;

  @Output()
  studentDataChangedEmitted = new EventEmitter<object>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private router: Router,
    private messagingService: MessagingService) {
  }

  ngOnInit() {
    this.updateControls(this.studentData);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.studentData && changes.studentData.currentValue) {
      this.updateControls(changes.studentData.currentValue);
    }
  }

  /**
   * Update checkboxes in essay navigation to reflect student's completion data
   */
  updateControls(changes) {
    this.subject.essays.forEach(essay => {
      this.controls[essay.nid] = false;
      changes.forEach(dataPoint => {
        if (dataPoint.nid == essay.nid && dataPoint.complete == true) {
          this.controls[essay.nid] = true;
        }
      });
    });
  }

  /**
   * Click handler for essay navigation.
   */
  navigateToEssay(essayNid) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {nid: essayNid}
    });
  }

  /**
   * Navigates to an essay, with the video open.
   */
  navigateToVideo(essayNid) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {nid: essayNid, scrollto: 'video'}
    });

    // If the route changes, the scrollto param is handled by the parent component,
    // but if we are already on the clicked essay, the routing would not trigger.
    this.messagingService.sendMessage('scroll-to-content', 'video');
  }

  /**
   * Click handler for essay ROE navigation.
   */
  navigateToROE() {
    this.router.navigate([], {
      relativeTo: this.route,
    });
  }

  /**
   * Click handler for essay navigation checkbox.
   */
  studentDataChangedEmit(event, nid, complete) {
    let params = {
      nid: nid,
      complete: complete ? 1 : 0,
    }
    this.studentDataChangedEmitted.emit(params);
  }

  /**
   * Click handler for audio file play.
   */
  startAudio(event, nid) {
    let params = {
      audio_id: nid,
      playlist: this.subject.essays
    }

    this.messagingService.sendMessage('start-audio', params);
  }

}
